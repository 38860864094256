import { useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
// utils
// components
import Iconify from "../../components/Iconify";
import MenuPopover from "../../components/MenuPopover";
import palette from "src/theme/palette";

export default function FilterPopover({ options }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        sx={{ top: 10, right: 20 }}
        onClick={() => handleOpen()}
      >
        <Iconify icon="eva:funnel-outline" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 250, padding: 1 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // px: 2.5,
          }}
        >
          {options.map((option) => (
            <Typography
              sx={{
                color: "black",
                fontSize: "14px",
                color: palette.secondary.main,
                cursor: "pointer",
                my: 1,
              }}
              onClick={() => {
                option.onClick();
                setOpen(false);
              }}
            >
              {option.title}
            </Typography>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
