import { UsermanagerHeader } from "./user-manager-header";

export default function UpdateManager() {


  return (
    
      <UsermanagerHeader/>
   
  );
}

