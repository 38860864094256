export const AdjustableModal = ({ children, title, size = "medium", onClose, modalDisplayClass }) => {
    return (
        <div className={`modal-overlay ${modalDisplayClass}`}>
            <div className={`modal-container ${size}`}>
                {/* Modal Header */}
                <div className="modal-header">
                    <h6 className="modal-title">{title}</h6>
                    <span className="modal-close" onClick={onClose}>✖</span>
                </div>

                {/* Modal Content */}
                <div className="modal-body">
                    {children}
                </div>
            </div>
        </div>
    );
};


