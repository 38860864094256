import CustomSelect from "./CustomSelect";

export const FilterDropDown = ({ options, label, name, onSelect }) => {
  return (
    <div className="filter-dropdown">
      <span className="dropdown-text">{label}</span>
      <CustomSelect options={options} name={name} onSelect={onSelect} />
    </div>
  );
};
