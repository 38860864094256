import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
// material
import {
  Card,
  Container,
  Typography,
  Grid,
  Box,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
// components
import Page from "../components/Page";
import DashBoardCard from "src/components/DashboardCard";
import styled from "@emotion/styled";
import AllAgents from "src/sections/@dashboard/app/AllAgentsTable";
import Iconify from "src/components/Iconify";
import OutlinedButton from "src/components/OutlinedButton";
import RecentTransactions from "src/sections/@dashboard/app/RecentTransactions";
import AlertDialogSlide from "src/components/SlideInDialog";
import IncreaseLimitDialog from "src/components/IncreaseDialog";
import TransferClientDialog from "src/components/TransferDialog";
import { apiFunctions } from "src/api";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  height: "250px",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: theme.spacing(20),
  alignItems: "center",
  width: theme.spacing(16),
  height: theme.spacing(16),
  justifyContent: "center",
  overflow: "hidden",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%)`,
}));

// ----------------------------------------------------------------------

export default function AgentProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [showIncreaseDialog, setShowIncreaseDialog] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("state => ", state);

  const getTransactions = async () => {
    try {
      setLoading(true);
      const res = await apiFunctions.getFATransactions(state?.fieldagent?.id);
      setLoading(false);
      if (res.status) {
        setTransactions(res.data);
        console.log("res fa trans => ", res);
      } else {
        console.log(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <Page title="Agents">
      <Container maxWidth="xl">
        <IconButton
          sx={{ position: "absolute", top: 100 }}
          onClick={() => navigate("/dashboard/agents", { replace: true })}
        >
          <Iconify icon="eva:arrow-back-outline" width={30} height={30} />
        </IconButton>
        <Grid container spacing={3} sx={{ mt: "30px" }}>
          <Grid item xs={12} sm={7} md={8} lg={7}>
            <RootStyle>
              <IconWrapperStyle>
                <img alt={"profile-pic"} src={null} />
              </IconWrapperStyle>
              <Box
                sx={{
                  ml: "50px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: "32px" }}>
                  {" "}
                  {state.fieldagent?.firstName +
                    " " +
                    state.fieldagent?.lastName}
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  {state?.fieldagent?.user?.email}
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  {state.fieldagent?.user?.phoneNumber}
                </Typography>
                <Divider sx={{ height: "10px" }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <OutlinedButton
                    buttonText={"Increase Limit"}
                    onClick={() => setShowIncreaseDialog(!showIncreaseDialog)}
                  />
                  <Divider sx={{ width: "10px" }} />
                  <OutlinedButton
                    buttonText={"Transfer Clients"}
                    onClick={() => setShowTransferDialog(!showTransferDialog)}
                  />
                </Box>
              </Box>
            </RootStyle>
          </Grid>
          <Grid spacing={3} item xs={12} sm={5} md={4} lg={5}>
            <DashBoardCard
              amount={state.fieldagent?.accountBalance}
              title="Account Balance"
              icon="cash"
            />
            <br />
            <DashBoardCard other={state.savers} title="Client" icon="agent" />
          </Grid>
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <Grid item>
            <RecentTransactions transactions={transactions} />
          </Grid>
        </Grid>
        {showIncreaseDialog && (
          <AlertDialogSlide
            dialogComponent={
              <IncreaseLimitDialog
                data={state}
                isClose={() => setShowIncreaseDialog(false)}
              />
            }
            isOpen={showIncreaseDialog}
            isClose={() => setShowIncreaseDialog(false)}
          />
        )}
        {showTransferDialog && (
          <AlertDialogSlide
            dialogComponent={
              <TransferClientDialog
                many={true}
                client_name={state.fieldagent?.firstName}
                isClose={() => setShowTransferDialog(false)}
                client_id={state.fieldagent?._id}
              />
            }
            isOpen={showTransferDialog}
            isClose={() => setShowTransferDialog(false)}
          />
        )}
      </Container>
    </Page>
  );
}
