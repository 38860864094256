import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  state: {
    isFetching: false,
    showToast: false,
    message: "",
    success: null,
  },
};

const appLoaderSlice = createSlice({
  name: "appLoader",
  initialState,
  reducers: {
    setShowToast: (state, action) => {
      state.state.showToast = action.payload;
    },
    setMessage: (state, action) => {
      state.state.message = action.payload;
    },
    setSuccess: (state, action) => {
      state.state.success = action.payload;
    },
  },
});

export const { setShowToast, setMessage, setSuccess } = appLoaderSlice.actions;

export default appLoaderSlice.reducer;
