// material
import {
    Card,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Table,
    Stack,
    Pagination,
    Grid
} from "@mui/material";
import { Link } from "react-router-dom";
import { AdjustableModal } from "src/pages/admins/commons/adjustable-modal";
import TablePaginationActions from "src/pages/admins/commons/table-pagination-action-component";
import GeneralHeader from "src/sections/@dashboard/user/GeneralHeader";
import { capitalizeFirstLetter, formatDate } from "src/utils/formatTime";
import EditAffiliateForm from "../commons/edit-affiliate-form";
import { apiFunctions } from "src/api";
import { useState } from "react";
import { toast } from "react-toastify";
import { resetObject } from "src/utils/general";
import { fNumber } from "src/utils/formatNumber";

export default function AffiliateTable({ affiliates, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, totalRows }) {
    const TABLE_HEAD = [
        { id: "fullname", label: " Full Name", alignRight: false },
        { id: "phone_number", label: "Phone Number", alignRight: false },
        { id: "email", label: "Email Address", alignRight: false },
        { id: "affiliate_code", label: "Affiliate Code", alignRight: false },
        { id: "referrals", label: " Referrals", alignRight: false },
        { id: "commision", label: "Commission (N)", alignRight: false },
        { id: "completed_plan", label: "Completed Savings Plans (N)", alignRight: false },
        { id: "active_plan", label: "Active Savings Plans (N)", alignRight: false },
        { id: "balance", label: "Wallet Balance (N)", alignRight: false },
        { id: "bvn", label: "BVN", alignRight: false },
        { id: "date", label: "Date Created", alignRight: false },
        { id: "action", label: "Actions", alignRight: false },
    ];


    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [fullname, setFullname] = useState('');
    const [affiliateModal, setAffiliateModal] = useState('hide-modal');



    const [formData, setFormData] = useState({
        nin: '',
        addess: '',
        phone: '',
        gAddress: '',
        gPhone: '',
        gName: '',
        gNin: '',
        gEmail: '',
        name: ''
    })


    const handlePaginationChange = (event, value) => {
        handleChangePage(event, value - 1); // Material-UI Pagination uses 1-based index
    }
    const [selectedAffiliate, setSelectedAffiliate] = useState(null);


    const handleInputChange = async (e) => {
        const { name, value } = e.target

        setFormData({
            ...formData,
            [name]: value,
        });
    };




    const closeCreateAffiliateModal = () => {
        setAffiliateModal('hide-modal');
    }

    const openCreateAffiliateModal = () => {
        setAffiliateModal('modal-overlay');
    }

    const handleAffiliateUpdate = async (e) => {
        e.preventDefault();

        const { gAddress, nin, gName, phone, gPhone, gEmail, address, gNin, id, name } = formData;

        // Validate required fields
        if (!gAddress || !nin || !gName || !phone || !gPhone || !gEmail || !gNin || name?.length < 1) {
            const msg = "Please supply all required fields";
            setMessage(msg);
            setIsLoading(false);
            setIsError(true);
            toast.error(msg);
            return;
        }

        try {

            setIsLoading(true); // Indicate loading state

            const createResponse = await apiFunctions.upateAffiliate({
                nin: nin,
                guarantorName: gName,
                guarantorHomeAddress: gAddress,
                guarantorNin: gNin,
                guarantorEmail: gEmail,
                guarantorPhone: gPhone,
                homeAddress: address,
                phone: phone,
                id
            })

            if (!createResponse?.status) {
                setMessage(createResponse?.message || "Validation completed.");
                return
            }

            closeCreateAffiliateModal()

            setFormData(resetObject(formData))

            setMessage(createResponse?.message || "Affiliate Data Updated");
            window.location.reload()
            setIsError(false);
        } catch (error) {
            setMessage(error?.message);
            setIsError(true);
            return
        } finally {
            setIsLoading(false);
        }
    };



    const handleEditClick = (event, affiliate) => {
        event.stopPropagation()
        event.preventDefault()
        openCreateAffiliateModal()
        setFormData({
            nin: affiliate.nin || '',
            address: affiliate.homeAddress || '',
            phone: affiliate.phone || '',
            gAddress: affiliate.guarantorHomeAddress || '',
            gPhone: affiliate.guarantorPhone || '',
            gName: affiliate.guarantorName || '',
            gNin: affiliate.guarantorNin || '',
            gEmail: affiliate.guarantorEmail || '',
            id: affiliate._id || '',
            name: affiliate?.saver.firstName + ' ' + affiliate?.saver?.lastName || ''
        });

    }

    return (
        <Card>
            <TableContainer>
                <Table>
                    <GeneralHeader headLabel={TABLE_HEAD} onRequestSort={() => { }} />
                    <TableBody>
                        {affiliates?.map((affiliate) => {

                            const {
                                commissions,
                                saver,
                                referrals,
                                phone,
                                affiliateCode,
                                _id,
                                createdAt,
                                activePlans,
                                completedPlans,
                                wallet,
                            } = affiliate;
                            const fullName = saver?.firstName + " " + saver?.lastName
                            return (

                                <TableRow
                                    hover key={_id}
                                    tabIndex={-1}

                                    component={referrals > 0 ? Link : ''}
                                    to={`/dashboard/affiliates/${affiliateCode}/referrals`}
                                    style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                                >
                                    <TableCell align="left"> {capitalizeFirstLetter(fullName)} </TableCell>
                                    <TableCell align="left">{phone}</TableCell>
                                    <TableCell align="left">{saver?.email}</TableCell>
                                    <TableCell align="left">{affiliateCode}</TableCell>
                                    <TableCell align="left">{referrals}</TableCell>
                                    <TableCell align="left">{commissions}</TableCell>
                                    <TableCell align="left">{completedPlans}</TableCell>
                                    <TableCell align="left">{activePlans}</TableCell>
                                    <TableCell align="left">{fNumber(wallet?.balance)}</TableCell>
                                    <TableCell align="left">{saver?.bvn}</TableCell>
                                    <TableCell align="left">{formatDate(createdAt)}</TableCell>
                                    <TableCell align="left" onClick={(event) => handleEditClick(event, affiliate)}>
                                        Edit Affiliate
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                    <TablePagination
                        rowsPerPageOptions={[20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 10000]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions} // Use the custom component

                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Pagination
                            count={Math.ceil(totalRows / rowsPerPage)}
                            page={page + 1} // Material-UI Pagination uses 1-based index
                            onChange={handlePaginationChange}
                            color="primary"
                        />
                    </Stack>
                </Grid>
            </Grid>

            <AdjustableModal title={'Edit Affiliate'} modalDisplayClass={affiliateModal}
                onClose={closeCreateAffiliateModal}>

                <EditAffiliateForm
                    onCancel={closeCreateAffiliateModal}
                    handleSubmit={handleAffiliateUpdate}
                    formData={formData}
                    handleChange={handleInputChange}

                    isError={isError}
                    message={message}
                    fullname={fullname}
                    loading={isLoading}
                    data={selectedAffiliate}
                />
            </AdjustableModal>
        </Card>
    );
}
