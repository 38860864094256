import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import PrimaryButton from "src/components/PrimaryButton";
import OutlinedButton from "src/components/OutlinedButton";
import { apiFunctions } from "src/api";
import { useDispatch } from "react-redux";
import { setIsAuthenticated, setUserData } from "src/redux/slice/userSlice";
import {
  setMessage,
  setShowToast,
  setSuccess,
} from "src/redux/slice/appLoaderSlice";

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPt2, setShowPt2] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const RegisterSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Full name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    phoneNo: Yup.string()
      .matches(
        /^(?:(?:(?:\+?234(?:\h1)?|01)\h*)?(?:\(\d{3}\)|\d{3})|\d{4})(?:\W*\d{3})?\W*\d{4}$/,
        "Phone Number must begin with +234"
      )
      .required("Phone number must be valid"),
    password: Yup.string().required("Password is required"),
    companyName: Yup.string().required("Company name required"),
    regNum: Yup.string().required("Registration number is required"),
    bvn: Yup.string()
      .min(11, "Please enter a valid bvn")
      .max(11, "Please enter a valid bvn")
      .required("BVN is required"),
    nin: Yup.string()
      .min(11, "Please enter a valid NIN")
      .max(11, "Please enter a valid NIN")
      .required("NIN is required"),
    address: Yup.string().required("Address is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      password: "",
      companyName: "",
      regNum: "",
      bvn: "",
      nin: "",
      address: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      console.log(values);
    },
  });

  const signUp = async () => {
    setLoading(true);
    try {
      const res = await apiFunctions.createSuperAgent({
        firstName: values.firstName,
        lastName: values.lastName,
        companyName: values.companyName,
        cacRegistrationNo: values.regNum,
        bvn: values.bvn,
        nin: values.nin,
        officeAddress: values.address,
        phoneNumber: values.phoneNo,
        email: values.email,
        password: values.password,
      });
      if (res.status) {
        dispatch(setShowToast(true));
        dispatch(setSuccess(true));
        dispatch(
          setMessage(
            "Registration Successful! Kindly be on the look out for an email from us with instructions on how to access your account."
          )
        );
        navigate("/login", { replace: true });
      } else {
        alert(res.message);
      }
      console.log(res);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          {showPt2 && (
            <IconButton
              sx={{ bottom: 20, marginRight: 1 }}
              onClick={() => setShowPt2(false)}
            >
              <Iconify icon="eva:arrow-back-outline" width={25} height={25} />
            </IconButton>
          )}

          <Typography sx={{ color: "text.black", mb: 5, fontWeight: "500px" }}>
            Create Account
          </Typography>
        </div>
        {!showPt2 ? (
          <Stack spacing={3}>
            <TextField
              fullWidth
              label="First Name"
              {...getFieldProps("firstName")}
              error={Boolean(touched.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <TextField
              fullWidth
              label="Last Name"
              {...getFieldProps("lastName")}
              error={Boolean(touched.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="email"
                type="email"
                label="Email address"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <TextField
                fullWidth
                autoComplete="phoneNumber"
                type="phone"
                label="Phone Number"
                {...getFieldProps("phoneNo")}
                error={Boolean(touched.phoneNo)}
                helperText={touched.phoneNo && errors.phoneNo}
              />
            </Stack>

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Password"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Confirm Password"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <PrimaryButton
              buttonText={"Continue"}
              icon={"ion:arrow-forward-circle-outline"}
              onClick={() => setShowPt2(true)}
            />
          </Stack>
        ) : (
          <Stack spacing={3}>
            <TextField
              fullWidth
              label="Company Name"
              {...getFieldProps("companyName")}
              error={Boolean(touched.companyName)}
              helperText={touched.companyName && errors.companyName}
            />

            <TextField
              fullWidth
              label="C.A.C. Registration Number"
              {...getFieldProps("regNum")}
              error={Boolean(touched.regNum && errors.regNum)}
              helperText={touched.regNum && errors.regNum}
            />

            <TextField
              fullWidth
              type="phone"
              label="Bank Verification Number"
              {...getFieldProps("bvn")}
              error={Boolean(touched.bvn)}
              helperText={touched.bvn && errors.bvn}
            />
            <TextField
              fullWidth
              type="phone"
              label="National Identity Number"
              {...getFieldProps("nin")}
              error={Boolean(touched.nin)}
              helperText={touched.nin && errors.nin}
            />
            <TextField
              fullWidth
              label="Office Address"
              {...getFieldProps("address")}
              error={Boolean(touched.address)}
              helperText={touched.address && errors.address}
            />
            <PrimaryButton
              buttonText={"Sign Up"}
              loading={loading}
              onClick={() => signUp()}
            />
          </Stack>
        )}
      </Form>
    </FormikProvider>
  );
}
