import { Link as RouterLink, useNavigate } from "react-router-dom";

// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Stack,
  Link,
  Container,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { LoginForm } from "../sections/authentication/login";
import AuthSocial from "../sections/authentication/AuthSocial";
import PrimaryButton from "src/components/PrimaryButton";
import { useState } from "react";
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  height: "100%",
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <RootStyle title="Reset Password | Ajolity">
      <img src="/static/woman.png" alt="login" />

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5, alignItems: "center", justifyContent: "center" }}>
            <img
              src="/static/ajolity-logo.png"
              alt="login"
              width={"190px"}
              height={"146px"}
            />
          </Stack>
          <Typography
            sx={{
              color: "text.black",
              mb: 3,
              fontWeight: "500px",
              fontSize: "20px",
            }}
          >
            Reset Password
          </Typography>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Enter New Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <br />

          <TextField
            fullWidth
            autoComplete="confirm-password"
            type={showPassword ? "text" : "password"}
            label="Confirm New Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <br />
          <PrimaryButton
            buttonText={"Reset Password"}
            onClick={() => navigate("/login", { replace: true })}
          />
          <Typography
            sx={{
              color: "text.black",
              mt: 10,
              fontWeight: "500px",
              textAlign: "center",
            }}
          >
            &copy; Ajolity 2022
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
