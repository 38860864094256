// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Box } from "@mui/material";
// utils
import { fShortenNumber } from "../utils/formatNumber";
// component
import Iconify from "./Iconify";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(40),
  paddingTop: "35px",
  paddingBottom: "35px",
  paddingLeft: "30px",
  paddingRight: "30px",
  backgroundColor: "white",
  width: theme.spacing(50),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: theme.spacing(20),
  alignItems: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: "center",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%)`,
}));

// ----------------------------------------------------------------------

export default function CustomDialog({ title, description }) {
  return (
    <RootStyle>
      <Typography sx={{ color: "black", fontSize: "20px", fontWeight: "bold" }}>
        {title}
      </Typography>

      <Box sx={{ my: "20px" }}>
        <img
          alt={"cash"}
          src={`/static/icons/check.svg`}
          width={80}
          height={80}
        />
      </Box>

      <Typography sx={{ color: "black", fontSize: "14px" }}>
        {description}
      </Typography>
    </RootStyle>
  );
}
