import React from "react";

import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Box } from "@mui/material";
// utils
import { fShortenNumber } from "../../src/utils/formatNumber";
// component
import Iconify from "../components/Iconify";
import "../utils/Spinner.css";

export default function LoadingIndicator({ buttonText, loading, icon }) {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
}
