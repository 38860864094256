import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Table,
  Stack,
  Box,
  Avatar,
  IconButton,
} from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
import { fShortenNumber } from "../../../utils/formatNumber";

//
import { BaseOptionChart } from "../../../components/charts";
import { UserListHead } from "../user";
import palette from "src/theme/palette";
import moment from "moment";
import Iconify from "src/components/Iconify";
import MenuPopover from "src/components/MenuPopover";
import { useEffect, useRef, useState } from "react";
import NotificationsPopover from "src/layouts/dashboard/NotificationsPopover";
import FilterPopover from "src/layouts/dashboard/FilterPopover";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

const CHART_DATA = [4344, 5435, 1443, 4443];

export default function TransactionsTable({ transactions, title = "" }) {
  const [trans, setTrans] = useState([...transactions]);
  const theme = useTheme();
  const [active, setActive] = useState("deposit");

  const filterTransaction = () => {
    var _temp = transactions.filter(
      (transaction) => transaction.type === active
    );
    setTrans(_temp);
  };

  const TABLE_HEAD = [
    { id: "image", label: "", alignRight: false },
    { id: "date", label: "Date", alignRight: false },
    { id: "time", label: "Time", alignRight: false },
    { id: "name", label: "Customer Name", alignRight: false },
    { id: "phone", label: "Phone Number", alignRight: false },
    { id: "amount", label: "Amount (N)", alignRight: false },
    { id: "agent", label: "Agent", alignRight: false },
    { id: "trans_type", label: "Transaction Type", alignRight: false },
  ];
  const filterOptions = [
    { title: "Deposits", onClick: () => setActive("deposit") },
    { title: "Withdrawals", onClick: () => setActive("withdrawal") },
    {
      title: "Unscheduled Withdrawals",
      onClick: () => setActive("unscheduledWithdrawal"),
    },
  ];

  useEffect(() => {
    filterTransaction();
  }, [active]);

  useEffect(() => {
    setTrans(transactions);
  }, [transactions]);

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <CardHeader title={title ? title : "Recent Transactions"} />
        <FilterPopover options={filterOptions} />
      </Stack>

      <TableContainer>
        <Table>
          <UserListHead headLabel={TABLE_HEAD} rowCount={transactions.length} />
          <TableBody>
            {trans.map((user) => {
              const { name, amount, client, type, fieldAgent, _id, createdAt } =
                user;

              return (
                <TableRow hover key={_id} tabIndex={-1}>
                  <TableCell component="th" scope="row" padding="10">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Avatar src={null} sx={{ width: 25, height: 25 }} />
                    </Stack>
                  </TableCell>
                  <TableCell align="left">
                    {moment(createdAt).format("LL")}
                  </TableCell>
                  <TableCell align="left">
                    {moment(createdAt).format("LT")}
                  </TableCell>
                  <TableCell component="th" scope="row" padding="10">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
                        {client?.fullName}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="left">{client?.phoneNumber}</TableCell>
                  <TableCell align="left">{amount}</TableCell>
                  <TableCell align="left">
                    {fieldAgent?.firstName + " " + fieldAgent?.lastName}
                  </TableCell>
                  <TableCell align="left">{type}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
