import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { LoginForm } from "../sections/authentication/login";
import AuthSocial from "../sections/authentication/AuthSocial";
import { RegisterForm } from "src/sections/authentication/register";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  height: "100%",
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(0, 0),
}));

// ----------------------------------------------------------------------

export default function Signup() {
  return (
    <RootStyle title="Login | Ajolity">
      <img src="/static/lady.png" alt="signup" />

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5, alignItems: "center", justifyContent: "center" }}>
            <img
              src="/static/icons/logo.svg"
              alt="login"
              // width={"190px"}
              // height={"146px"}
            />
          </Stack>
          {/* <Typography sx={{ color: "text.black", mb: 5, fontWeight: "500px" }}>
            Create Account
          </Typography> */}

          <RegisterForm />
          <Typography
            variant="body2"
            align="center"
            sx={{
              mt: 3,
            }}
          >
            Already have an account?&nbsp;
            <Link
              variant="subtitle2"
              component={RouterLink}
              to="/login"
              underline="hover"
            >
              Log In
            </Link>
          </Typography>
          <Typography
            sx={{
              color: "text.black",
              mt: 10,
              fontWeight: "500px",
              textAlign: "center",
            }}
          >
            &copy; Bankvole 2022
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
