// material
import { styled } from "@mui/material/styles";
import {  Container, Typography } from "@mui/material";
// layouts
// components
import Page from "src/components/Page";
import MessageNotificationForm from "./messgae-notifcation/message";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  height: "100%",
}));


const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 0),
}));

// ----------------------------------------------------------------------

export default function NotificationMessage() {
  const thisYear = new Date().getFullYear()
  return (
    <RootStyle title="Message Notification">
      <Container maxWidth="sm">
        <ContentStyle>
        

          <MessageNotificationForm />
      
          <Typography
            sx={{
              color: "text.black",
              mt: 10,
              fontWeight: "500px",
              textAlign: "center",
            }}
          >
            &copy; Bankvole {thisYear}
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
