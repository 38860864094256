import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <RouterLink to="/">
      <img
        alt={"home"}
        src={`/static/icons/logowhite.svg`}
        width={100}
        height={100}
        style={{
          marginBottom: 40,
        }}
      />
    </RouterLink>
  );
}
