import { useEffect, useState } from "react";

import {
  Container,
  Grid,
  Stack,
  CircularProgress,
} from "@mui/material";

import AlertDialogSlide from "src/components/SlideInDialog";
import CreateClientForm from "src/components/CreateClientForm";
import { apiFunctions } from "src/api";
import Page from "src/components/Page";
import AffiliateTable from "./tables/affiliates-tabels";
import { AffiliateDataFilter } from "./commons/affiliates-filter";
import { AffiliatesDashboardCommonData } from "./commons/affiliate-dashboard-common-data";
import { AdjustableModal } from "../admins/commons/adjustable-modal";
import AffiliateForm from "./commons/create-affiliate-form";
import { toast } from "react-toastify";
import { resetObject } from "src/utils/general";


export default function AffiliateRecords() {
  const [loading, setLoading] = useState(false);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [modalClass, setModalClass] = useState('hide-modal');
  const [fullname, setFullname] = useState('');
  const [affiliateModal, setAffiliateModal] = useState('hide-modal');


  const [formData, setFormData] = useState({
    // nin: '',
    // addess: '',
    phone: '',
    // gAddress: '',
    // gPhone: '',
    // gName: '',
    // gNin: '',
    // gEmail: '',
    id:''
  })

  const [queryData, setQueryData] = useState({
    status: true,
    page: 20
  })

  const handlePhoneInputChange = async (e) => {
    const { name, value } = e.target;

    setIsLoading(true)
    try {
      const res = await apiFunctions.validateAffiliatePhone({ phone: value });
      setFullname(`${res?.data?.firstName || ""} ${res?.data?.lastName || ""}`);
      setMessage('Phone number validated')
      setIsLoading(false)
      setIsError(false)
    } catch (error) {
      setFullname("");  // Ensure fullname is cleared if validation fails
      setIsError(true)
      setMessage(error?.message)
      setIsLoading(false)
    }
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (name, value) => {
    setQueryData({
      ...queryData,
      [name]: value,
    });
  }


  const handleModalClose = () => {
    setModalClass('hide-modal');
  }

  const handleModalOpen = () => {
    setModalClass('show-modal');
  }

  const closeCreateAffiliateModal = () => {
    setAffiliateModal('hide-modal');
  }

  const openCreateAffiliateModal = () => {
    setAffiliateModal('modal-overlay');
  }


  const getAffiliates = async (page, rowsPerPage, query) => {
    try {
      setLoading(true);
      const res = await apiFunctions.getAffiliates(page, rowsPerPage, query);
      setData(res?.data)
      setTotalRows(res?.total)
      setLoading(false)
      handleModalClose()//Close the modal
    } catch (e) {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDataExport = async () => {
    await apiFunctions.downloadExcelFile(
      'admin-client/reports/transaction/export', 'transactions'
    )
  }


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleAffiliateSubmission = async (e) => {
    e.preventDefault();

    const { gAddress, nin, gName, phone, gPhone, gEmail, address, gNin,id } = formData;

    // Validate required fields
    if ( !phone) {
      const msg = "Please supply all required fields";
      setMessage(msg);
      setIsLoading(false);
      setIsError(true);
      toast.error(msg);
      return;
    }

    try {

      setIsLoading(true); // Indicate loading state

      const createResponse = await apiFunctions.createAffiliate({
        // nin: nin,
        // guarantorName: gName,
        // guarantorHomeAddress: gAddress,
        // guarantorNin: gNin,
        // guarantorEmail: gEmail,
        // guarantorPhone: gPhone,
        // homeAddress: address,
        phone: phone
      })

      if (!createResponse?.status) {
        setMessage(createResponse?.message || "Validation completed.");
        return
      }

      closeCreateAffiliateModal()
      getAffiliates(page + 1, rowsPerPage, queryData)


      setFormData(resetObject(formData))

      setMessage(createResponse?.message || "Affiliate Data Create");

      setIsError(false);
    } catch (error) {
      setMessage(error?.message);
      setIsError(true);
      return
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilter = async (event) => {
    event.preventDefault();
    const { status } = queryData

    await getAffiliates(page + 1, queryData.page, { status })

    setRowsPerPage(queryData.page)
  }

  useEffect(() => {
    getAffiliates(page + 1, rowsPerPage, queryData);
  }, [page, rowsPerPage]);

  return (
    <Page title="Clients">
      <Container maxWidth="xl">
        <AffiliatesDashboardCommonData
          handleModal={handleModalOpen}
          exportData={handleDataExport}
          affiliateModal={openCreateAffiliateModal}
          data={data}
        />

        {message && (
          <p className={`${isError ? 'error' : 'success'}`}>
            {message}
          </p>
        )}
        <Grid sx={{ mt: 3 }}>
          {loading ? (
            <Grid item xs={12} md={12} lg={12}>
              <Stack
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 10,
                }}
              >
                <CircularProgress />
              </Stack>
            </Grid>
          ) : (
            <AffiliateTable
            
              affiliates={data?.affiliates}
              totalRows={totalRows}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          )}
        </Grid>
        {showFormDialog && (
          <AlertDialogSlide
            isOpen={showFormDialog}
            isClose={() => setShowFormDialog(false)}
            dialogComponent={<CreateClientForm />}
          />
        )}

        <AdjustableModal title={'Filter Data'} modalDisplayClass={modalClass} onClose={handleModalClose}>
          <AffiliateDataFilter
            handleSubmit={handleFilter}
            data={formData}
            handleSelectChange={handleSelectChange}
            cancel={handleModalClose}
          />
        </AdjustableModal>
        {/* <ScreenLoader status={loading}/> */}
        <AdjustableModal title={'Create Affiliate'} modalDisplayClass={affiliateModal}
          onClose={closeCreateAffiliateModal}>

          <AffiliateForm
            onCancel={closeCreateAffiliateModal}
            handleSubmit={handleAffiliateSubmission}
            formData={formData}
            handleChange={handleInputChange}
            handlePhoneInputChange={handlePhoneInputChange}
            isError={isError}
            message={message}
            fullname={fullname}
            loading={isLoading}
          />
        </AdjustableModal>

      </Container>
    </Page>
  );
}
