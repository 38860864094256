import React, { useState } from "react";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Box, Divider } from "@mui/material";
// utils
import { fShortenNumber } from "../../src/utils/formatNumber";
// component
import Iconify from "../components/Iconify";
import palette from "src/theme/palette";
import AlertDialogSlide from "./SlideInDialog";
import SuspendDialog from "./SuspendDialog";
import DeactivateDialog from "./DeactivateDialog";
import IncreaseLimitDialog from "./IncreaseDialog";
import ActivateDialog from "./ActivateDialog";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(2, 2),
  backgroundColor: "white",
}));

// ----------------------------------------------------------------------

export default function AgentOptions({ data, icon, amount, title }) {
  const [showSuspendDialog, setShowSuspendDialog] = useState(false);
  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
  const [showActivateDialog, setShowActivateDialog] = useState(false);
  const [showIncreaseDialog, setShowIncreaseDialog] = useState(false);
  return (
    <RootStyle>
      <Typography
        sx={{
          color: "black",
          fontSize: "14px",
          color: palette.primary.main,
          cursor: "pointer",
        }}
        onClick={() => setShowIncreaseDialog(!showIncreaseDialog)}
      >
        Increase Limit
      </Typography>
      <Divider color={palette.divider} sx={{ my: 0.5 }} />
      <Typography
        sx={{
          color: "black",
          fontSize: "14px",
          color: "gray",
          cursor: "pointer",
        }}
        onClick={() => {
          !data.fieldagent?.isActive && !data.fieldagent?.isDeleted
            ? setShowActivateDialog(!showActivateDialog)
            : setShowSuspendDialog(!showSuspendDialog);
        }}
      >
        {!data.fieldagent?.isActive && !data.fieldagent?.isDeleted
          ? "Activate"
          : "Suspend"}
      </Typography>
      <Divider color={palette.divider} sx={{ my: 0.5 }} />
      <Typography
        sx={{
          color: "black",
          fontSize: "14px",
          color: "red",
          mt: "5px",
          cursor: "pointer",
        }}
        onClick={() => setShowDeactivateDialog(!showDeactivateDialog)}
      >
        Deactivate
      </Typography>
      {showSuspendDialog && (
        <AlertDialogSlide
          dialogComponent={
            <SuspendDialog
              isClose={() => setShowSuspendDialog(false)}
              data={data}
            />
          }
          isOpen={showSuspendDialog}
          isClose={() => setShowSuspendDialog(false)}
        />
      )}
      {showDeactivateDialog && (
        <AlertDialogSlide
          dialogComponent={
            <DeactivateDialog
              isClose={() => setShowDeactivateDialog(false)}
              data={data}
            />
          }
          isOpen={showDeactivateDialog}
          isClose={() => setShowDeactivateDialog(false)}
        />
      )}
      {showActivateDialog && (
        <AlertDialogSlide
          dialogComponent={
            <ActivateDialog
              isClose={() => setShowActivateDialog(false)}
              data={data}
            />
          }
          isOpen={showActivateDialog}
          isClose={() => setShowActivateDialog(false)}
        />
      )}
      {showIncreaseDialog && (
        <AlertDialogSlide
          dialogComponent={
            <IncreaseLimitDialog
              data={data}
              isClose={() => setShowIncreaseDialog(false)}
            />
          }
          isOpen={showIncreaseDialog}
          isClose={() => setShowIncreaseDialog(false)}
        />
      )}
    </RootStyle>
  );
}
