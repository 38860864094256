import { Grid, Stack, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import DashBoardCard from "src/components/DashboardCard";
import { fNumber } from "src/utils/formatNumber";

export const ProcessWithdrawals = ({ handleSubmit, data, handelInputChange, isSubmitting, balance, message, isError }) => {

  return (
    <>

      <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center', }}>
        <Stack spacing={2} width={'40rem'} marginBottom={'30px'}>
          <Grid item xs={12} sm={6} md={120}>
            <DashBoardCard icon={"balance"} other={`N${fNumber(balance?.balance)}`} title="Account Balance" >
            </DashBoardCard>
          </Grid>
        </Stack>
        <Stack spacing={2} width={'40rem'} marginBottom={'30px'}>
          <TextField
            disabled={isSubmitting}
            fullWidth
            autoComplete="amount"
            type="number"
            placeholder="Amount"
            label="Amount"
            value={data?.amount}
            onChange={handelInputChange}
            name="amount"
            required
          />
          <TextField
            disabled={isSubmitting}
            fullWidth
            autoComplete="naration"
            type="text"
            placeholder="Naration"
            label="Naration"
            value={data?.naration}
            onChange={handelInputChange}
            name="naration"
          />
        </Stack>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{display:'flex'}}>
            <button className="custom_btn"
              style={{ background: "#2502a3", color: "#fff", padding: '5px', borderRadius: "5px", marginRight:"20px" }}

            >Withdraw Balance</button>

            <Link style={{ background: "#2502a3", color: "#fff", padding: '5px', borderRadius: "5px",  }}
              className="link-url" to={'/dashboard/transfer/logs'}>Back</Link>

          </div>

        </Grid>
        <Grid>
          <span className={`${isError ? "text-danger" : ''}`}>{message}</span>
        </Grid>
      </form>
    </>
  )
}
