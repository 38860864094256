import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { useSelector, useDispatch } from "react-redux";
// import { uuid } from "uuidv4";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import palette from "src/theme/palette";
// component
import Iconify from "../../../components/Iconify";
import PrimaryButton from "src/components/PrimaryButton";
import {
  setIsAuthenticated,
  setIsFetching,
  setUserData,
} from "src/redux/slice/userSlice";
import { apiFunctions } from "src/api";
import {
  setMessage,
  setShowToast,
  setSuccess,
} from "src/redux/slice/appLoaderSlice";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  // const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        const res = await apiFunctions.login({
          email: values.email,
          password: values.password,
          firebaseToken: "token",
        });
        if (res.status) {
          if (res.data.role !== "superagent" && res?.data.role !== 'admin') {
            dispatch(setShowToast(true));
            dispatch(setSuccess(false));
            dispatch(setMessage("Please login again as a Superagent or as an admin"));
          } else {
            dispatch(setIsAuthenticated(true));
            localStorage.setItem("token", res.data.token);
            dispatch(setUserData(res.data));
            navigate("/dashboard/overview", { replace: true });
          }
        } else {
          alert(res.message);
        }
        console.log(res);
      } catch (e) {
        console.log(e);
      }
      // dispatch(setIsAuthenticated(true));
      // navigate("/dashboard/overview", { replace: true });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            disabled={isSubmitting}
            fullWidth
            autoComplete="username"
            type="email"
            label="Email/Phone Number"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            disabled={isSubmitting}
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Radio {...getFieldProps("remember")} checked={values.remember} />
            }
            label="Remember me"
          />

          <Link
            component={RouterLink}
            variant="subtitle2"
            to="/forgotPassword"
            underline="hover"
          >
            Forgot password ?
          </Link>
        </Stack>

        <PrimaryButton
          buttonText={"Log In"}
          loading={isSubmitting}
          // icon={"ion:arrow-forward-circle-outline"}
          onClick={() => handleSubmit()}
        />
        {/* <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          // color={palette.primary.main}
        >
          Login
        </LoadingButton> */}
      </Form>
    </FormikProvider>
  );
}
