import React, { useState } from 'react';
import { Box, MenuItem, Typography, ClickAwayListener } from '@mui/material';
import { CaretSVG } from './caretSVG';

const CustomSelect = ({ options, name, onSelect }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(<CaretSVG />);

  const toggleDropdown = () => setOpen(!open);
  const handleSelect = (label, value) => {
    setSelected(label);
    setOpen(false);
    onSelect(name, value);
  };

  const handleClickAway = () => setOpen(false);

  const style = {
    border: "2px solid grey",
    borderRadius: "4px",
    marginRight: '10px',
    fontSize: "1rem",
    padding: "2px 5px"
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box position="relative" display="inline-block">
        <Box
          className="select-box"
          sx={{
            border: 'none',
            padding: '2px 16px',
            borderRadius: '4px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'flex-start'
          }}
          onClick={toggleDropdown}
        >
          <Typography>{selected}</Typography>
        </Box>

        {open && (
          <Box
            className="dropdown-options"
            sx={{
              position: 'absolute',
              top: '100%',
              right: '0%',
              backgroundColor: 'inherit',
              borderRadius: '4px',
              zIndex: 1000,
              display: 'flex',
              justifyContent: 'space-evenly',
              flexWrap: "no-wrap",
              alignItems: "center"
            }}
          >
            {options?.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => handleSelect(option.label, option.value)}
                sx={{ margin: '0 8px' }}
                style={style}
              >
                {option.label}
              </MenuItem>
            ))}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default CustomSelect;
