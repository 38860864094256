import { useEffect, useState } from "react";

import {
  Container,
  Grid,
  Stack,
  CircularProgress,
} from "@mui/material";

import AlertDialogSlide from "src/components/SlideInDialog";
import CreateClientForm from "src/components/CreateClientForm";
import { apiFunctions } from "src/api";
import Page from "src/components/Page";
import { ScreenLoader } from "src/components/Screen-Loader";
import { AffiliatesReferralDashboardCommonData } from "./commons/affiliate-referral-dashboard-common-data";
import AffiliateReferralTable from "./tables/affiliates-referrals-table";
import { useParams } from "react-router-dom";


export default function AffiliateReferralRecords() {
  const {affiliateCode} = useParams()

  const [loading, setLoading] = useState(false);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);


  const getAffiliatesReferrals = async (page, rowsPerPage, query) => {
    try {
      console.log(query)
      setLoading(true);
      const res = await apiFunctions.getAffiliatesReferrals(page, rowsPerPage, query);
      setData(res?.data);
      setTotalRows(res?.total);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }


  useEffect(() => {
    getAffiliatesReferrals(page + 1, rowsPerPage, {code: affiliateCode});
  }, [page, rowsPerPage]);

  return (
    <Page title="Clients">
      <ScreenLoader status={loading}/>
      <Container maxWidth="xl">
        <AffiliatesReferralDashboardCommonData
          user={data?.user}
          data={data}
        />
        <Grid sx={{ mt: 3 }}>
          {loading ? (
            <Grid item xs={12} md={12} lg={12}>
              <Stack
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 10,
                }}
              >
                <CircularProgress />
              </Stack>
            </Grid>
          ) : (
            <AffiliateReferralTable
              data={data}
              totalRows={totalRows}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          )}
        </Grid>
        {showFormDialog && (
          <AlertDialogSlide
            isOpen={showFormDialog}
            isClose={() => setShowFormDialog(false)}
            dialogComponent={<CreateClientForm />}
          />
        )}

      </Container>
    </Page>
  );
}
