// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "overview",
    path: "/dashboard/overview",
    icon: (
      <img alt={"home"} src={`/static/icons/home.svg`} width={25} height={25} />
    ),
  },
  {
    title: "agents",
    path: "/dashboard/agents",
    icon: (
      <img
        alt={"home"}
        src={`/static/icons/agent-icon.svg`}
        width={25}
        height={25}
      />
    ),
  },
  {
    title: "savers",
    path: "/dashboard/savers",
    icon: (
      <img
        alt={"home"}
        src={`/static/icons/client-icon.svg`}
        width={25}
        height={25}
      />
    ),
  },

  {
    title: "affiliate",
    path: "/dashboard/affiliates",
    icon: (
      <img
        alt={"home"}
        src={`/static/icons/client-icon.svg`}
        width={25}
        height={25}
      />
    ),
  },

  {
    title: "Client Reports",
    path: "/dashboard/admin/plans",
    icon: (
      <img
        alt={"home"}
        src={`/static/icons/client-icon.svg`}
        width={25}
        height={25}
      />
    ),
  },
  
  {
    title: "Messages",
    path: "/dashboard/messages",
    icon: (
      <img
        alt={"home"}
        src={`/static/icons/client-icon.svg`}
        width={25}
        height={25}
      />
    ),
  },
  
  {
    title: "transactions",
    path: "/dashboard/transactions",
    icon: (
      <img
        alt={"home"}
        src={`/static/icons/coins-hand.svg`}
        width={50}
        height={50}
      />
    ),
  },

  {
    title: "User Management",
    path: "/dashboard/users",
    icon: (
      <img
        alt={"home"}
        src={`/static/icons/coins-hand.svg`}
        width={50}
        height={50}
      />
    ),
  },
  
  {
    title: "verifyme kyc",
    path: "/dashboard/verifyme-kyc",
    icon: (
      <img
        alt={"home"}
        src={`/static/icons/coins-hand.svg`}
        width={50}
        height={50}
      />
    ),
  },
  
];

export default sidebarConfig;
