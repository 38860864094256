const banks = [
  {
    code: "023",
    canActivateOnline: false,
    name: "CITIBANK",
  },
  {
    code: "214",
    canActivateOnline: false,
    name: "FIRST CITY MONUMENT BANK PLC",
  },
  {
    code: "011",
    canActivateOnline: false,
    name: "FIRST BANK PLC",
  },
  {
    code: "035",
    canActivateOnline: false,
    name: "WEMA BANK PLC",
  },
  {
    code: "039",
    canActivateOnline: false,
    name: "STANBIC IBTC BANK",
  },
  {
    code: "033",
    canActivateOnline: false,
    name: "UNITED BANK FOR AFRICA PLC",
  },
  {
    code: "101",
    canActivateOnline: true,
    name: "PROVIDUS BANK",
  },
  {
    code: "044",
    canActivateOnline: false,
    name: "ACCESS BANK NIGERIA",
  },
  {
    code: "050",
    canActivateOnline: false,
    name: "ECOBANK NIGERIA PLC",
  },
  {
    code: "057",
    canActivateOnline: true,
    name: "ZENITH BANK PLC",
  },
  {
    code: "063",
    canActivateOnline: false,
    name: "DIAMOND BANK PLC",
  },
  {
    code: "076",
    canActivateOnline: false,
    name: "SKYE BANK PLC",
  },
  {
    code: "082",
    canActivateOnline: true,
    name: "KEYSTONE BANK PLC",
  },
  {
    code: "232",
    canActivateOnline: true,
    name: "STERLING BANK PLC",
  },
  {
    code: "032",
    canActivateOnline: false,
    name: "UNION BANK OF NIGERIA PLC",
  },
  {
    code: "030",
    canActivateOnline: false,
    name: "HERITAGE BANK",
  },
  {
    code: "301",
    canActivateOnline: true,
    name: "JAIZ BANK",
  },
  {
    code: "058",
    canActivateOnline: false,
    name: "GTBANK PLC",
  },
  {
    code: "070",
    canActivateOnline: false,
    name: "FIDELITY BANK PLC",
  },
  {
    code: "068",
    canActivateOnline: false,
    name: "STANDARD CHARTERED BANK NIGERIA LIMITED",
  },
  {
    code: "100",
    canActivateOnline: true,
    name: "SUNTRUST BANK NIG LTD",
  },
  {
    code: "215",
    canActivateOnline: false,
    name: "UNITY BANK PLC",
  },
];

export default banks;
