
export const Modal = ({ children, title, modalDisplayClass, onClose }) => {
    return (
        <div className={`transaction-filter-modal-overlay ${modalDisplayClass}`} >
            <div className="transaction-filter-modal">
                <div style={{ display: "flex" }}>
                    <h6 className="filter-by-text">{title}</h6>
                    <span className="close-filter-modal" onClick={onClose}>X</span>
                </div>
                {children}
            </div>
        </div>
    );
};
