// material
import {
    Card,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Table,
    Stack,
    Pagination,
    Grid
} from "@mui/material";
import { Link } from "react-router-dom";
import TablePaginationActions from "src/pages/admins/commons/table-pagination-action-component";
import GeneralHeader from "src/sections/@dashboard/user/GeneralHeader";
import { capitalizeFirstLetter, formatDate } from "src/utils/formatTime";

export default function AffiliateReferralTable({ data, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, totalRows }) {
    const TABLE_HEAD = [
        { id: "fullname", label: " Full Name", alignRight: false },
        { id: "email", label: "Email", alignRight: false },
        { id: "phone_number", label: "Phone Number", alignRight: false },
        { id: "bvn", label: "BVN", alignRight: false },
        { id: "balance", label: "Balance (N)", alignRight: false },
        { id: "completed_plan", label: "Completed Savings Plans (N)", alignRight: false },
        { id: "active_plan", label: "Active Savings Plans (N)", alignRight: false },
        { id: "referraCode", label: " Referral Code", alignRight: false },
        { id: "date_joined", label: "Date Joined", alignRight: false },
    ];


    const handlePaginationChange = (event, value) => {
        handleChangePage(event, value - 1); // Material-UI Pagination uses 1-based index
    };


    return (
        <Card>
            <TableContainer>
                <Table>
                    <GeneralHeader headLabel={TABLE_HEAD} onRequestSort={() => { }} />
                    <TableBody>
                        {data?.savers?.map((referrals) => {

                            const {
                                saver,
                                _id,
                                wallet,
                                referralCodes,
                                activePlan,
                                completedPlan
                            } = referrals;
                            const fullName = saver?.firstName + " " + saver?.lastName
                            return (
                                <TableRow>
                                    <TableCell align="left"> {capitalizeFirstLetter(fullName)} </TableCell>
                                    <TableCell align="left">{saver?.email}</TableCell>
                                    <TableCell align="left">{saver?.phoneNumber}</TableCell>
                                    <TableCell align="left">{saver?.bvn}</TableCell>
                                    <TableCell align="left">{wallet?.balance}</TableCell>
                                    <TableCell align="left">{completedPlan}</TableCell>
                                    <TableCell align="left">{activePlan}</TableCell>
                                    <TableCell align="left">{referralCodes?.code}</TableCell>
                                    <TableCell align="left">{formatDate(saver?.createdAt)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                    <TablePagination
                        rowsPerPageOptions={[20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 10000]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions} // Use the custom component

                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Pagination
                            count={Math.ceil(totalRows / rowsPerPage)}
                            page={page + 1} // Material-UI Pagination uses 1-based index
                            onChange={handlePaginationChange}
                            color="primary"
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    );
}
