const ProfileCard = ({data, user}) => {

    return (
      <div className="profile-card" style={{marginRight:'30rem !important'}}>
        <div className="profile-image">
          <img src={`data:image/jpeg;base64,${user?.profileImage}`} alt="Profile" />
        </div>
        <div className="profile-info">
          <h2>{data?.saver?.firstName + ' ' + data?.saver?.lastName}</h2>
          <p><strong>Email:</strong> {data?.saver?.email}</p>
          <p><strong>Phone:</strong> {data?.phone}</p>
          <p><strong>Affiliate code:</strong> {data?.affiliateCode}</p>
          <p><strong>Address:</strong> {data?.homeAddress}</p>
          <p><strong>NIN:</strong> {data?.nin}</p>
        </div>
      </div>
    );
  };
  
  export default ProfileCard;
  