
const UpdateBankForm = ({ onCancel, handleSubmit, formData, banks, handleChange, message, isError, loading }) => {

    return (
        <>

            <form className="affiliate-form" onSubmit={handleSubmit}>

                {message && (
                    <p className={`${isError ? 'error' : 'success'}`}>
                        {message}
                    </p>
                )}

                <label>Bank Name</label>
                <select
                    required
                    name="bankId"
                    value={formData?.bankId}
                    onChange={handleChange}
                >
                    <option value="">select bank</option>
                    {
                        banks?.map(bank => (
                            <option key={bank._id} value={bank._id}>{bank.name}</option>
                        ))
                    }
                </select>

                <label>Account Number</label>
                <input type="text" placeholder="e.g 0293029302" name="accountNumber" value={formData?.accountNumber} onChange={handleChange} />

                <label>BVN</label>
                <input type="number" placeholder="e.g. 223232322322" name="bvn" value={formData?.bvn} onChange={handleChange} />

                <div className="form-footer">
                    <button type="button" className="btn cancel-btn" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="btn upgrade-btn" disabled={loading ?? false}>
                        {loading ? 'Updating............., please wait' : 'Update'}
                    </button>
                </div>


            </form>
        </>
    );
};

export default UpdateBankForm;