
const OtherFieldUpdateForm = ({ onCancel, handleSubmit, formData,  handleChange, message, isError, loading }) => {
const fields = ['email', 'firstname', 'lastname']
    return (
        <>

            <form className="affiliate-form" onSubmit={handleSubmit}>

                {message && (
                    <p className={`${isError ? 'error' : 'success'}`}>
                        {message}
                    </p>
                )}

                <label>Field To Update</label>
                <select
                    required
                    name="field"
                    value={formData?.field}
                    onChange={handleChange}
                >
                    <option value="">select field</option>
                    {
                        fields?.map(field => (
                            <option key={field} value={field}>{field}</option>
                        ))
                    }
                </select>

                <label>Field Value</label>
                <input type="text" placeholder="e.g Idris" name="value" value={formData?.value} onChange={handleChange} />

                <label>BVN</label>
                <input type="number" placeholder="e.g. 223232322322" name="bvn" value={formData?.bvn} onChange={handleChange} />

                <div className="form-footer">
                    <button type="button" className="btn cancel-btn" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="btn upgrade-btn" disabled={loading ?? false}>
                        {loading ? 'Updating............., please wait' : 'Update'}
                    </button>
                </div>


            </form>
        </>
    );
};

export default OtherFieldUpdateForm;