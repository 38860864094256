import {  Grid } from "@mui/material";
import { ExportIconSVG } from "src/components/ExportIconSVG";

export const VerifymeKYCExportData = ({  exportData }) => {


    return (
        <>
            <Grid container spacing={4} wrap="wrap">


                <Grid item xs={12} sm={4} md={3} container spacing={1} className="plans-div">
                    <Grid item xs={6}>
                        <div className="export" style={{ cursor: 'pointer' }} onClick={exportData}>
                            <button style={{ background: 'inherit', color: '#fff', border: 'none', cursor: 'pointer' }}>Export</button>
                            <ExportIconSVG />
                        </div>
                    </Grid>
                    
                </Grid>
            </Grid>
        </>
    );
};
