
const ResetPinForm = ({ onCancel, handleSubmit, formData,  handleChange, message, isError, loading }) => {

    return (
        <>
            <form className="affiliate-form" onSubmit={handleSubmit}>

                {message && (
                    <p className={`${isError ? 'error' : 'success'}`}>
                        {message}
                    </p>
                )}
                <label>Email Address</label>
                <input type="email" placeholder="e.g youremail@example.com" name="email" value={formData?.email} onChange={handleChange} />

                <label>PIN</label>
                <input type="number" maxLength={4} placeholder="e.g. 1234" name="pin" value={formData?.pin} onChange={handleChange} />

                <div className="form-footer">
                    <button type="button" className="btn cancel-btn" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="btn upgrade-btn" disabled={loading ?? false}>
                        {loading ? 'Resetting............., please wait' : 'Reset'}
                    </button>
                </div>
            </form>
        </>
    );
};

export default ResetPinForm;