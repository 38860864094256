import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import {  useDispatch } from "react-redux";

import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
} from "@mui/material";

// component
import Iconify from "../../../components/Iconify";
import PrimaryButton from "src/components/PrimaryButton";
import {
  setIsAuthenticated,
  setUserData,
} from "src/redux/slice/userSlice";
import { apiFunctions } from "src/api";
import {
  setMessage,
  setShowToast,
  setSuccess,
} from "src/redux/slice/appLoaderSlice";

// ----------------------------------------------------------------------

export default function AdminLoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{9,14}$/;

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .test(
        'emailOrPhone',
        'Must be a valid email address or phone number',
        value => {
          // Check if the value is either a valid email or a valid phone number
          return Yup.string().email().isValidSync(value) || phoneRegExp.test(value);
        }
      )
      .required('Email or phone number is required'),
    password: Yup.string().required('Password is required'),
  });


  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        const res = await apiFunctions.login({
          email: values.email,
          password: values.password,
        });
        if (res.status) {
          if (res.data.role !== "admin") {
            dispatch(setShowToast(true));
            dispatch(setSuccess(false));
            dispatch(setMessage("Please login again as an admin"));
          } else {
            dispatch(setIsAuthenticated(true));
            localStorage.setItem("token", res.data.token);
            dispatch(setUserData(res.data));
            navigate("/dashboard/admin/plans", { replace: true });
          }
        } else {
          dispatch(setShowToast(true));
          dispatch(setSuccess(false));
          dispatch(setMessage(res.message));
        }
        console.log(res);
      } catch (e) {
        console.log(e);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            disabled={isSubmitting}
            fullWidth
            autoComplete="email"
            type="text"
            placeholder="email/phone"
            label="Email/Phone Number"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            disabled={isSubmitting}
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            placeholder="*****"
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Radio 
              {...getFieldProps("remember")} 
              checked={values.remember}
               />
            }
            label="Remember me"
          />

          <Link
            component={RouterLink}
            variant="subtitle2"
            to="/forgotPassword"
            underline="hover"
            color={'gray'}
          >
            Forgot password?
          </Link>
        </Stack>

        <PrimaryButton
          buttonText={"Log in"}
          loading={isSubmitting}
          onClick={() => handleSubmit()}
          background={"#2502a3"}
          
        />

        {/* <AdminPrimaryButton 
          onClick={handleSubmit}
        /> */}
        
      </Form>
    </FormikProvider>
  );
}


