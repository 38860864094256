import { useFormik } from "formik";
import { useEffect, useState } from "react";
// material
import {
  Container,
  Grid,
  Stack,
  styled,
  Typography,
  Card,
  Box,
  CircularProgress,
} from "@mui/material";
// components
import Page from "../components/Page";
import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar,
} from "../sections/@dashboard/products";
//
import PRODUCTS from "../_mocks_/products";
import RecentTransactions from "src/sections/@dashboard/app/RecentTransactions";
import ClientTable from "src/sections/@dashboard/app/ClientTable";
import DashBoardCard from "src/components/DashboardCard";
import AlertDialogSlide from "src/components/SlideInDialog";
import CreateAgentForm from "src/components/CreateAgentForm";
import CreateClientForm from "src/components/CreateClientForm";
import TransactionsTable from "src/sections/@dashboard/app/TransactionsTable";
import { apiFunctions } from "src/api";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  alignItems: "center",
  padding: theme.spacing(3, 5),
  backgroundColor: "white",
  height: theme.spacing(14),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",

  borderRadius: theme.spacing(20),
  alignItems: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: "center",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%)`,
}));

export default function Transactions() {
  const [openFilter, setOpenFilter] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [withdrawals, setWithdrawals] = useState();
  const [deposits, setDeposits] = useState();
  const [unscheduled, setUnscheduled] = useState();

  const formik = useFormik({
    initialValues: {
      gender: "",
      category: "",
      colors: "",
      priceRange: "",
      rating: "",
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  const getTransactions = async () => {
    try {
      setLoading(true);
      const res = await apiFunctions.getTransactions();
      setLoading(false);
      if (res.status) {
        setTransactions(res.data);
        console.log("res trans => ", res);
      } else {
        console.log(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getBalance = async () => {
    setLoading(true);
    try {
      const res = await apiFunctions.getTransSummary();
      setLoading(false);
      if (res.status) {
        setDeposits(res.data.deposits);
        setWithdrawals(res.data.withdrawals);
        setUnscheduled(res.data.unscheduledWithdrawals);
        console.log("res top balance => ", res);
      } else {
        console.log(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getTransactions();
    getBalance();
  }, []);

  return (
    <Page title="Clients">
      <Container maxWidth="xl">
        {loading ? (
          <Grid item xs={12} md={12} lg={12}>
            <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
              <CircularProgress />
            </Stack>
          </Grid>
        ) : (
          <>
            <Grid container spacing={3} wrap="wrap">
              <Grid item xs={12} sm={6} md={4}>
                <DashBoardCard
                  icon={"wallet-add"}
                  other={deposits}
                  title="Deposits"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DashBoardCard
                  icon={"wallet-minus"}
                  other={withdrawals}
                  title="Withdrawals"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashBoardCard
                  icon={"wallet-que"}
                  other={unscheduled}
                  title="Unscheduled Withdrawals"
                />
              </Grid>
            </Grid>
            <Grid sx={{ mt: 3 }}>
              {loading ? (
                <Grid item xs={12} md={12} lg={12}>
                  <Stack
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 10,
                    }}
                  >
                    <CircularProgress />
                  </Stack>
                </Grid>
              ) : (
                <TransactionsTable
                  transactions={transactions}
                  // title={"All Deposits"}
                />
              )}
            </Grid>
          </>
        )}

        {showFormDialog && (
          <AlertDialogSlide
            isOpen={showFormDialog}
            isClose={() => setShowFormDialog(false)}
            dialogComponent={<CreateClientForm />}
          />
        )}
      </Container>
    </Page>
  );
}
