import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader, Box } from "@mui/material";
//
import { BaseOptionChart } from "../../../components/charts";

// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    name: "Withdrawals",
    type: "line",
    data: [50, 60, 50, 30, 35, 40, 80],
  },
  {
    name: "Deposits",
    type: "line",
    data: [44, 55, 41, 10, 22, 30, 70],
  },
];

export default function CollectionStats() {
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [5, 5] },
    plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
    fill: { type: ["solid", "solid"] },
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    xaxis: { type: "days" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader title="Collection Rate" subheader="(+43%) than last year" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="area"
          series={CHART_DATA}
          options={chartOptions}
          height={336}
        />
      </Box>
    </Card>
  );
}
