import { Box, Grid, Typography } from "@mui/material";

import {
  Stack,
  TextField,
} from "@mui/material";

// component
import PrimaryButton from "src/components/PrimaryButton";

import { apiFunctions } from "src/api";

import { useState } from "react";

// ----------------------------------------------------------------------

export default function ResetPin() {
  const [isLoading, setIsLoading] = useState(false)

  const [message, setMessage] = useState('')
  const [isError, setIsError] = useState(false)
  const [formData, setFormData] = useState({
    pin: '',
    email: ''
  })


  const handleInputChange = (e) => {
    setMessage('')
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setMessage('')
    try {

      if (!formData.pin || formData.pin?.length !== 4) {
        const msg = "PIN must be exactly 4 characters"
        setMessage(msg)
        setIsLoading(false);
        setIsError(true)
      }
      else if (!formData.email) {
        const msg = "Email field is required"
        setMessage(msg)
        setIsLoading(false);
        setIsError(true)
      }
      else {

        const res = await apiFunctions.resetPin({
          pin: formData.pin,
          email: formData.email,
        });

        if (res.status) {
          setFormData({
            pin: "",
            email: ""
          })
          // window.location.reload()
        }
        setIsError(false)
        setMessage(res?.message)
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
      setMessage(e?.message)
      setIsError(true)
    }
  }


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full viewport height
        padding: 2, // Add padding for better mobile responsiveness
      }}
    >
      <Grid
        container
        maxWidth="sm" // Optional: Limit the form width
        sx={{
          backgroundColor: "white", // Optional: Add a background color if needed
          boxShadow: 3, // Optional: Add a shadow for better visibility
          borderRadius: 2, // Optional: Rounded corners
          padding: 4, // Add spacing around the form
        }}
      >
        <Stack spacing={3} width="100%">
          <TextField
            disabled={isLoading}
            name="email"
            value={formData.email}
            fullWidth
            autoComplete="email"
            type="email"
            placeholder="Email"
            label="Email"
            onChange={handleInputChange}
          />

          <TextField
            disabled={isLoading}
            autoComplete="current-pin"
            placeholder="Enter PIN"
            label="PIN"
            name="pin"
            value={formData.pin}
            onChange={handleInputChange}
          />

          {message && (
            <Typography color= {`${isError ? '#981818' : '#0b480c'}`} marginBottom="10px">
              {message}
            </Typography>
          )}

          <PrimaryButton
            buttonText="Reset PIN"
            loading={isLoading}
            background="#b7a6f0"
            onClick={handleFormSubmit}
          />
        </Stack>
      </Grid>
    </Box>
  );
}

