import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  Button,
  Container,
  Typography,
} from "@mui/material";

import { alpha, styled } from "@mui/material/styles";

import PrimaryButton from "src/components/PrimaryButton";
import banks from "src/helpers/banks";
import CustomSelector from "./CustomSelector";
import { apiFunctions } from "src/api";
import { useDispatch } from "react-redux";
import {
  setMessage,
  setShowToast,
  setSuccess,
} from "src/redux/slice/appLoaderSlice";

// ----------------------------------------------------------------------

const ButtonWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  borderRadius: "8px",
  border: "1px solid #1C2ECE",
  borderStyle: "dashed",
  alignItems: "center",
  paddingLeft: "30px",
  paddingRight: "30px",
  justifyContent: "center",
  color: theme.palette.primary.contrastText,
  backgroundImage: "transparent",
}));

export default function CreateAgentForm({ isClose }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [bank, setBank] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const resetState = () => {
    dispatch(setShowToast(false));
    dispatch(setMessage(""));
    dispatch(setSuccess(null));
  };

  const CreateAgentSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    middleName: Yup.string().required("Middle name is required"),
    phoneNumber: Yup.number().required("Phone number is required"),

    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),

    password: Yup.string().required("Password is required"),
    bvn: Yup.number().required("BVN is required"),
    nin: Yup.number().required("NIN is required"),
    accountNumber: Yup.number().required("Account Number is required"),
    accountLimit: Yup.number().required("Account Limit is required"),
  });

  const addAgent = async () => {
    resetState();
    setLoading(true);
    const payload = {
      ...values,
      bvn: values.bvn,
      nin: values.nin,
      accountNumber: values.accountNumber,
      bankName: bank,
      accountLimit: +values.accountLimit,
    };
    console.log(payload);
    try {
      const res = await apiFunctions.addFieldAgent(payload);
      if (res.status) {
        dispatch(setShowToast(true));
        dispatch(setSuccess(true));
        dispatch(setMessage(res.message));
        isClose();
      } else {
        dispatch(setShowToast(true));
        dispatch(setSuccess(false));
        dispatch(setMessage(res.message));
      }
      dispatch(setShowToast(false));
    } catch (e) {
      console.log(e);
      // resetState();
      // updateState();
      dispatch(setShowToast(true));
      dispatch(setSuccess(false));
      dispatch(setMessage(e.error));
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      phoneNumber: "",
      email: "",
      password: "",
      bvn: "",
      nin: "",
      accountNumber: "",
      accountLimit: "",
    },
    validationSchema: CreateAgentSchema,
    onSubmit: () => {
      bank !== null &&
      values.bvn.toString().length === 11 &&
      values.nin.toString().length === 11
        ? addAgent()
        : alert("Please Validate input fields!");
      // return true;
      isSubmitting = false;
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Container sx={{ width: "600px", py: 3 }}>
      <Typography sx={{ fontWeight: "bold", fontSize: "16px", mb: 3 }}>
        Create Agent
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ mb: 3 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              <TextField
                disabled={loading}
                fullWidth
                autoComplete="first name"
                type="name"
                label="First Name (same as Bank Account Name)"
                error={touched.firstName && errors.firstName}
                helperText={touched.firstName && errors.firstName}
                {...getFieldProps("firstName")}
              />
              <TextField
                disabled={loading}
                fullWidth
                autoComplete="last name"
                type="name"
                label="Last Name (same as Bank Account Name)"
                error={touched.lastName && errors.lastName}
                helperText={touched.lastName && errors.lastName}
                {...getFieldProps("lastName")}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              <TextField
                disabled={loading}
                fullWidth
                autoComplete="middle name"
                type="name"
                label="Middle Name (same as Bank Account Name)"
                error={touched.middleName && errors.middleName}
                helperText={touched.middleName && errors.middleName}
                {...getFieldProps("middleName")}
              />
              <TextField
                disabled={loading}
                fullWidth
                autoComplete="email"
                type="email"
                label="Email Address"
                error={touched.email && errors.email}
                helperText={touched.email && errors.email}
                {...getFieldProps("email")}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              <TextField
                disabled={loading}
                fullWidth
                autoComplete="phone"
                type="phone"
                label="Phone Number"
                error={touched.phoneNumber && errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                {...getFieldProps("phoneNumber")}
              />
              <TextField
                disabled={loading}
                fullWidth
                autoComplete="bvn"
                type="string"
                label="Bank Verification Number (BVN)"
                error={touched.bvn && errors.bvn}
                helperText={touched.bvn && errors.bvn}
                {...getFieldProps("bvn")}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              <TextField
                disabled={loading}
                fullWidth
                autoComplete="nin"
                type="string"
                label="National Identification Number (NIN)"
                error={touched.nin && errors.nin}
                helperText={touched.nin && errors.nin}
                {...getFieldProps("nin")}
              />

              <TextField
                disabled={loading}
                fullWidth
                autoComplete="account"
                type="string"
                label="Account Number"
                error={touched.accountNumber && errors.accountNumber}
                helperText={touched.accountNumber && errors.accountNumber}
                {...getFieldProps("accountNumber")}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              <TextField
                disabled={loading}
                fullWidth
                autoComplete="account limit"
                type="phone"
                label="Account Limit"
                error={touched.accountLimit && errors.accountLimit}
                helperText={touched.accountLimit && errors.accountLimit}
                {...getFieldProps("accountLimit")}
              />
              <TextField
                disabled={loading}
                fullWidth
                autoComplete="password"
                type="password"
                label="Password"
                error={touched.password && errors.password}
                helperText={touched.password && errors.password}
                {...getFieldProps("password")}
              />
            </div>
            <CustomSelector
              selectData={banks}
              title="Select Bank"
              setSelect={(e) => {
                setBank(e);
              }}
            />

            {/* <Button variant="outlined" component="label">
              Upload Image
              <input hidden accept="image/*" multiple type="file" />
            </Button> */}
          </Stack>

          <PrimaryButton
            buttonText={"Create Agent"}
            loading={loading}
            onClick={() => handleSubmit()}
          />
          {/* <LoadingButton
          
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          // color={palette.primary.main}
        >
          Login
        </LoadingButton> */}
        </Form>
      </FormikProvider>
    </Container>
  );
}
