import { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { merge } from "lodash";

// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Table,
  Stack,
  Box,
  Avatar,
} from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
import { fShortenNumber } from "../../../utils/formatNumber";

//
import { BaseOptionChart } from "../../../components/charts";
import { UserListHead } from "../user";
import palette from "src/theme/palette";
import Iconify from "src/components/Iconify";
import MenuPopover from "src/components/MenuPopover";
import AgentOptions from "src/components/AgentOptions";
import ShowHideOption from "src/components/ShowHideOption";
import FilterPopover from "src/layouts/dashboard/FilterPopover";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

const CHART_DATA = [4344, 5435, 1443, 4443];

export default function AllAgents({ allAgents }) {
  const [tempAgents, setTempAgents] = useState([...allAgents]);
  const theme = useTheme();
  const navigate = useNavigate();
  const [active, setActive] = useState("");

  const filterAgents = () => {
    // setTempAgents(allAgents);

    if (active === "active") {
      let _tempAgents = tempAgents.filter(
        (agent) => agent.fieldagent.isActive === true
      );
      setTempAgents(_tempAgents);
    } else if (active === "deactivated") {
      let _tempAgents = tempAgents.filter(
        (agent) => agent.fieldagent.isDeleted === true
      );
      setTempAgents(_tempAgents);
    } else {
      setTempAgents(allAgents);
    }
  };

  const filterOptions = [
    { title: "Active", onClick: () => setActive("active") },
    { title: "Suspended", onClick: () => setActive("suspended") },
    { title: "Deactivated", onClick: () => setActive("deactivated") },
  ];

  const TABLE_HEAD = [
    { id: "image", label: "", alignRight: false },
    { id: "agent", label: "Agent", alignRight: false },
    { id: "phone", label: "Phone Number", alignRight: false },
    { id: "balance", label: "Balance", alignRight: false },
    { id: "limit", label: "Account Limit", alignRight: false },
    { id: "client_size", label: "Client Size", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "", label: "", alignRight: false },
  ];

  useEffect(() => {
    filterAgents();
  }, [active]);

  useEffect(() => {
    setTempAgents(allAgents);
  }, [allAgents]);

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <CardHeader title="All Agents" />
        <FilterPopover options={filterOptions} />
      </Stack>

      <TableContainer>
        <Table>
          <UserListHead headLabel={TABLE_HEAD} rowCount={allAgents.length} />
          <TableBody>
            {tempAgents.map((user) => {
              const { fieldagent, savers } = user;

              return (
                <TableRow hover key={fieldagent._id} tabIndex={-1}>
                  <TableCell component="th" scope="row" padding="10">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Avatar
                        src={null}
                        sx={{ width: 25, height: 25, cursor: "pointer" }}
                        onClick={() =>
                          navigate("/dashboard/agentProfile", {
                            replace: false,
                            state: user,
                          })
                        }
                      />
                    </Stack>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="10">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/dashboard/agentProfile", {
                            replace: false,
                            state: user,
                          })
                        }
                      >
                        {fieldagent?.firstName + " " + fieldagent?.lastName}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="left">
                    {fieldagent?.user?.phoneNumber}
                  </TableCell>
                  <TableCell align="left">
                    {fieldagent?.accountBalance}
                  </TableCell>
                  <TableCell align="left">{fieldagent?.accountLimit}</TableCell>
                  <TableCell align="left">{savers}</TableCell>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: fieldagent?.isActive
                          ? "green"
                          : fieldagent?.isDeleted
                          ? "red"
                          : "gray",
                      }}
                    >
                      {fieldagent?.isActive
                        ? "Active"
                        : fieldagent?.isDeleted
                        ? "Deactivated"
                        : "Suspended"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <ShowHideOption data={user} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
