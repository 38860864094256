// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Box, TextField } from "@mui/material";
// utils
import { fShortenNumber } from "../utils/formatNumber";
// component
import Iconify from "./Iconify";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./PrimaryButton";
import OutlinedButton from "./OutlinedButton";
import { useState } from "react";
import CustomDialog from "./CustomCheckDialog";
import AlertDialogSlide from "./SlideInDialog";
import { useDispatch } from "react-redux";
import { apiFunctions } from "src/api";
import {
  setMessage,
  setShowToast,
  setSuccess,
} from "src/redux/slice/appLoaderSlice";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(45),
  paddingTop: "35px",
  paddingBottom: "35px",
  paddingLeft: "30px",
  paddingRight: "30px",
  backgroundColor: "white",
  width: theme.spacing(50),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: theme.spacing(20),
  alignItems: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: "center",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%)`,
}));

// ----------------------------------------------------------------------

export default function IncreaseLimitDialog({
  title,
  description,
  isClose,
  data,
}) {
  const [showCustomDialog, setShowCustomDialog] = useState(false);
  const [limit, setLimit] = useState();
  const dispatch = useDispatch();
  const resetState = () => {
    dispatch(setShowToast(false));
    dispatch(setMessage(""));
    dispatch(setSuccess(null));
  };

  const increaseLimit = async () => {
    try {
      const res = await apiFunctions.increaseLimit(data?.fieldagent?._id, {
        newLimit: +limit,
      });
      if (res.status) {
        dispatch(setShowToast(true));
        dispatch(setSuccess(true));
        dispatch(setMessage(res?.message));
        setShowCustomDialog(!showCustomDialog);
      } else {
        dispatch(setShowToast(true));
        dispatch(setSuccess(false));
        dispatch(setMessage(res?.message));
        console.log(res);
      }
    } catch (err) {
      console.log(err);
      dispatch(setShowToast(true));
      dispatch(setSuccess(false));
      dispatch(setMessage(err?.error));
    }
  };

  return (
    <RootStyle>
      <Typography sx={{ color: "black", fontSize: "20px", fontWeight: "bold" }}>
        Increase Limit
      </Typography>

      <Box sx={{ my: "20px" }}>
        <TextField
          sx={{ width: "245px" }}
          fullWidth
          autoComplete="Amount"
          type="number"
          label="Amount"
          onChange={(e) => setLimit(e.target.value)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <OutlinedButton buttonText={"Cancel"} onClick={isClose} />
        <PrimaryButton
          buttonText={"Confirm"}
          onClick={() => {
            resetState();
            increaseLimit();
          }}
        />
      </Box>
      {showCustomDialog && (
        <AlertDialogSlide
          dialogComponent={
            <CustomDialog
              title="Top up"
              description={`Successfully increased ${
                data.fieldagent?.firstName + " " + data.fieldagent?.lastName
              }’s limit to N${limit}`}
            />
          }
          isOpen={showCustomDialog}
          isClose={() => {
            setShowCustomDialog(false);
            isClose();
          }}
        />
      )}
    </RootStyle>
  );
}
