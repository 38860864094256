import { Grid, Stack, TextField } from "@mui/material";

export const ProcessVerification = ({ handleSubmit, data, handelInputChange, isSubmitting, message, isError }) => {

  return (
    <>

      <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center', }}>
        <Grid item xs={12} sm={12} md={12} columns={3}>
          <Stack direction="row" spacing={2} width="40rem" marginBottom="30px">
            <TextField
              disabled={isSubmitting}
              fullWidth
              autoComplete="bvn"
              type="number"
              placeholder="BVN"
              label="BVN"
              value={data?.bvn}
              onChange={handelInputChange}
              name="bvn"
              required
            />
            <button
              className="custom_btn"
              style={{
                background: "#2502a3",
                color: "#fff",
                padding: '5px 15px',
                borderRadius: "5px"
              }}
            >
              Verify
            </button>
          </Stack>
        </Grid>

        <Grid>
          <span className={`${isError ? "text-danger" : ''}`}>{message}</span>
        </Grid>
      </form>
    </>
  )
}
