import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    isAuthenticated: false,
    data: {},
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsFetching: (state, action) => {
      state.state.isFetching = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.user.isAuthenticated = action.payload;
    },
    setUserData: (state, action) => {
      state.user.data = action.payload;
    },
  },
});

export const { setIsFetching, setIsAuthenticated, setUserData } =
  userSlice.actions;

export default userSlice.reducer;
