import {
  Navigate,
  useRoutes
} from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import Overview from "./pages/Overview";
import Savers from "./pages/Savers";
import Agents from "./pages/Agents";
import NotFound from "./pages/Page404";
import Signup from "./pages/Signup";
import AgentProfile from "./pages/AgentProfile";
import ClientProfile from "./pages/ClientProfile";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Transactions from "./pages/Transactions";
import ProtectedRoute from "./utils/ProtectedRoute";
import AdminLogin from "./pages/admins/Login";
import AdminPlan from "./pages/admins/AdminPlan";
import AdminTransaction from "./pages/admins/AdminTransaction";
import AdminCustomerInformation from "./pages/admins/AdminCustomerInformation";
import NotificationMessage from "./pages/admins/notification-message";
import WithdrawBalance from "./pages/admins/account-balance-withdrawals/Withdraw-balance";
import AdminTransferLogs from "./pages/admins/TransferLogs";
import VerifymeKYC from "./pages/admins/verifymekyc/verifyme-kyc";
import ResetPin from "./pages/admins/reset-pin/reset-pin";


export default function Router() {
  return (
   
    useRoutes([
      {
        path: "/dashboard",
        element: (
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        ),

        children: [
          {
            path: "overview",
            element: (
              <ProtectedRoute>
                <Overview />
              </ProtectedRoute>
            ),
          },
          {
            path: "agents",
            element: (
              <ProtectedRoute>
                <Agents />
              </ProtectedRoute>
            ),
          },
          {
            path: "savers",
            element: (
              <ProtectedRoute>
                <Savers />
              </ProtectedRoute>
            ),
          },
          {
            path: "transactions",
            element: (
              <ProtectedRoute>
                <Transactions />
              </ProtectedRoute>
            ),
          },

          {
            path: "users",
            element: (
              <ProtectedRoute>
                <ResetPin />
              </ProtectedRoute>
            ),
          },
          
          {
            path: "verifyme-kyc",
            element: (
              <ProtectedRoute>
                <VerifymeKYC />
              </ProtectedRoute>
            ),
          },

          {
            path: "agentProfile",
            element: (
              <ProtectedRoute>
                <AgentProfile />
              </ProtectedRoute>
            ),
          },
          {
            path: "clientProfile",
            element: (
              <ProtectedRoute>
                <ClientProfile />
              </ProtectedRoute>
            ),
          },
          {
            path:"admin/plans", 
            element: (
              <ProtectedRoute>
                <AdminPlan/>
              </ProtectedRoute>
          )
          },

          {
            path:"admin/transactions", 
            element: (
              <ProtectedRoute>
                <AdminTransaction/>
              </ProtectedRoute>
          )
          },

          {
            path:"admin/customer/information", 
            element: (
              <ProtectedRoute>
                <AdminCustomerInformation/>
              </ProtectedRoute>
          )
          },

          {
            path:"messages", 
            element: (
              <ProtectedRoute>
                <NotificationMessage/>
              </ProtectedRoute>
          )
          },

          {
            path:"withdrawals", 
            element: (
              <ProtectedRoute>
                <WithdrawBalance/>
              </ProtectedRoute>
          )
          },
          {
            path:"transfer/logs", 
            element: (
              <ProtectedRoute>
                <AdminTransferLogs/>
              </ProtectedRoute>
          )
          },

        ],
      },

      {
        path: "/",
        element: <LogoOnlyLayout />,
        children: [
          { path: "/", element: <Navigate to="/login" /> },
          { path: "login", element: <Login /> },
          { path: "forgotPassword", element: <ForgotPassword /> },
          { path: "resetPassword", element: <ResetPassword /> },
          { path: "signup", element: <Signup /> },
        ],
      },

      {
         path :"/admin",
         element :<AdminLogin/>,
         children : [
        ]
      },
      
    ])
  );
}
