// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Box, TextField, Divider } from "@mui/material";
// utils
import { fShortenNumber } from "../utils/formatNumber";
// component
import Iconify from "./Iconify";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./PrimaryButton";
import OutlinedButton from "./OutlinedButton";
import { useState } from "react";
import AlertDialogSlide from "./SlideInDialog";
import CustomDialog from "./CustomCheckDialog";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(45),
  paddingTop: "35px",
  paddingBottom: "35px",
  paddingLeft: "30px",
  paddingRight: "30px",
  backgroundColor: "white",
  width: theme.spacing(50),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: theme.spacing(20),
  alignItems: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: "center",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%)`,
}));

// ----------------------------------------------------------------------

export default function AttachClientDialog({
  title,
  description,
  isClose,
  client_name,
}) {
  const [showCustomDialog, setShowCustomDialog] = useState(false);
  const [agentName, setAgentName] = useState("");
  return (
    <RootStyle>
      <Typography sx={{ color: "black", fontSize: "20px", fontWeight: "bold" }}>
        Attach Client
      </Typography>

      <Typography sx={{ color: "black", fontSize: "14px", lineHeight: "25px" }}>
        Confirming this means the agent whose name is inputed below would be
        assigned to the customer henceforth.
      </Typography>

      <Box sx={{ my: "20px" }}>
        <TextField
          sx={{ width: "335px" }}
          fullWidth
          autoComplete="Agent Name"
          type="name"
          label="Agent Name"
          onChange={(e) => setAgentName(e.target.value)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <OutlinedButton buttonText={"Cancel"} onClick={isClose} />
        <Divider sx={{ width: "20px" }} />
        <PrimaryButton
          buttonText={"Confirm"}
          onClick={() => {
            setShowCustomDialog(!showCustomDialog);
          }}
        />
      </Box>
      {showCustomDialog && (
        <AlertDialogSlide
          dialogComponent={
            <CustomDialog
              title="Attach Client"
              description={`Succesfully Attached ${client_name} to ${agentName}`}
            />
          }
          isOpen={showCustomDialog}
          isClose={() => {
            setShowCustomDialog(false);
            isClose();
          }}
        />
      )}
    </RootStyle>
  );
}
