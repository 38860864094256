import {
    Card,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Table,
    Stack,
    Pagination,
    Grid
} from "@mui/material";
import GeneralHeader from "src/sections/@dashboard/user/GeneralHeader";
import { useState } from "react";
import { capitalizeFirstLetter, formatDate } from "src/utils/formatTime";
import TablePaginationActions from "../commons/table-pagination-action-component";

export default function AdminCustomerInformationTable({ plans, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, totalRows }) {
    const TABLE_HEAD = [
        { id: "full_name", label: " Full Name", alignRight: false },
        { id: "email", label: "Email", alignRight: false },
        { id: "phone_no", label: "Phone No", alignRight: false },
        { id: "bvn", label: "BVN", alignRight: false },
        { id: "bankbook_id", label: "Bankbook ID", alignRight: false },
        { id: "paystack_id", label: "Paystack Wallet", alignRight: false },
        { id: "total_balance", label: "Total Balance (N)", alignRight: false },
        { id: "available_balance", label: "Available Balance (N)", alignRight: false },
        { id: "date_joined", label: "Date Joined", alignRight: false },
    ];

    

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };


    const handlePaginationChange = (event, value) => {
        handleChangePage(event, value - 1); // Material-UI Pagination uses 1-based index
    };

    const sortedData = [...plans].sort((a, b) => {
        if (orderBy === "createdAt" ) {
            const dateA = new Date(a[orderBy]);
            const dateB = new Date(b[orderBy]);
            return order === "asc" ? dateA - dateB : dateB - dateA;
        } else if (orderBy === "amount") {
            return order === "asc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
        } else if (orderBy === "type" ) {
            return order === "asc"
                ? a[orderBy].localeCompare(b[orderBy])
                : b[orderBy].localeCompare(a[orderBy]);
        }
        return 0;
    });

    return (
        <Card>
            <TableContainer>
                <Table>
                    <GeneralHeader headLabel={TABLE_HEAD} rowCount={plans?.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                    <TableBody>
                        {sortedData.map((plan) => {
                            const {
                                firstName,
                                lastName,
                                email,
                                phoneNumber,
                                bankbookClientId,
                                _id,
                                wallet,
                                createdAt,
                                bvn,
                                paystackAccountId
                            } = plan;

                            return (
                                <TableRow hover key={_id} tabIndex={-1}>
                                    <TableCell align="left"> { capitalizeFirstLetter(firstName)} {capitalizeFirstLetter( lastName)}</TableCell>
                                    <TableCell align="left">{email}</TableCell>
                                    <TableCell align="left">{phoneNumber}</TableCell>
                                    <TableCell align="left">{bvn}</TableCell>
                                    <TableCell align="left">{bankbookClientId}</TableCell>
                                    <TableCell align="left">{paystackAccountId}</TableCell>
                                    <TableCell align="left">{wallet.balance}</TableCell>
                                    <TableCell align="left">{wallet.availableForWithdrawal}</TableCell>
                                    <TableCell align="left">{formatDate(createdAt)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                    <TablePagination
                        rowsPerPageOptions={[20,  25, 30, 40, 50,100, 10000]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions} // Use the custom component
                        
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Pagination
                            count={Math.ceil(totalRows / rowsPerPage)}
                            page={page + 1} // Material-UI Pagination uses 1-based index
                            onChange={handlePaginationChange}
                            color="primary"
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    );
}
