// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Box, TextField, Divider } from "@mui/material";
// utils
import { fShortenNumber } from "../utils/formatNumber";
// component
import Iconify from "./Iconify";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./PrimaryButton";
import OutlinedButton from "./OutlinedButton";
import { useState } from "react";
import AlertDialogSlide from "./SlideInDialog";
import CustomDialog from "./CustomCheckDialog";
import { useDispatch } from "react-redux";
import { apiFunctions } from "src/api";
import {
  setMessage,
  setShowToast,
  setSuccess,
} from "src/redux/slice/appLoaderSlice";
import { useEffect } from "react";
import CustomSelector from "./CustomSelector";
import FieldAgentSelector from "./FieldAgentSelector";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(45),
  paddingTop: "35px",
  paddingBottom: "35px",
  paddingLeft: "30px",
  paddingRight: "30px",
  backgroundColor: "white",
  width: theme.spacing(50),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: theme.spacing(20),
  alignItems: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: "center",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%)`,
}));

// ----------------------------------------------------------------------

export default function TransferClientDialog({
  title,
  description,
  many = false,
  isClose,
  client_name,
  client_id,
}) {
  const [showCustomDialog, setShowCustomDialog] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [selectedAgent, setSelectedAgent] = useState({});

  const dispatch = useDispatch();

  const resetState = () => {
    dispatch(setShowToast(false));
    dispatch(setMessage(""));
    dispatch(setSuccess(null));
  };

  const transfer = async () => {
    try {
      const res = many
        ? await apiFunctions.transferClientsToFA({
            fromAgentId: client_id,
            toAgentId: selectedAgent._id,
          })
        : await apiFunctions.transferAgent({
            clientId: client_id,
            fieldAgentTo: selectedAgent._id,
          });
      if (res.status) {
        dispatch(setShowToast(true));
        dispatch(setSuccess(true));
        dispatch(setMessage(res?.message));
      } else {
        dispatch(setShowToast(true));
        dispatch(setSuccess(false));
        dispatch(setMessage(res?.message));
        console.log(res);
      }
    } catch (err) {
      console.log(err);
      dispatch(setShowToast(true));
      dispatch(setSuccess(false));
      dispatch(setMessage(err?.error));
    }
  };

  return (
    <RootStyle>
      <Typography sx={{ color: "black", fontSize: "20px", fontWeight: "bold" }}>
        Transfer Client
      </Typography>

      <Typography sx={{ color: "black", fontSize: "14px", lineHeight: "25px" }}>
        {many
          ? `Are you sure you want to transfer all savers under this agent to another agent?`
          : `Are you sure you want to transfer this client to another agent?`}
        <br />
        <br />
        Confirming this means the agent whose name is inputed below would be
        assigned to the customer henceforth.
      </Typography>
      <Box sx={{ my: "30px" }}>
        <FieldAgentSelector selectedAgent={(e) => setSelectedAgent(e)} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <OutlinedButton buttonText={"Cancel"} onClick={isClose} />
        <Divider sx={{ width: "20px" }} />
        <PrimaryButton
          buttonText={"Confirm"}
          onClick={() => {
            resetState();
            transfer();
            isClose();
          }}
        />
      </Box>
      {showCustomDialog && (
        <AlertDialogSlide
          dialogComponent={
            <CustomDialog
              title="Transfer Client"
              description={`Succesfully Transferred ${client_name} to ${agentName}`}
            />
          }
          isOpen={showCustomDialog}
          isClose={() => {
            setShowCustomDialog(false);
            isClose();
          }}
        />
      )}
    </RootStyle>
  );
}
