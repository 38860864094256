import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { apiFunctions } from "src/api";
import { useState } from "react";
import { useRef } from "react";

export default function FieldAgentSelector({ selectedAgent }) {
  const [value, setValue] = useState("");
  const [fieldAgents, setFieldAgents] = useState([]);

  const handleChange = (event) => {
    setValue(event.target.value);
    selectedAgent(event.target.value);
  };

  const getFieldAgents = async () => {
    try {
      const res = await apiFunctions.getAllFieldAgents();
      if (res.status) {
        setFieldAgents(res.data);
      } else {
        console.log(res);
      }
    } catch (e) {
      console.log("error ->", e);
    }
  };

  React.useEffect(() => {
    getFieldAgents();
  }, []);
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          Select Field Agent
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={"Select Field Agent"}
          onChange={handleChange}
        >
          {fieldAgents.map((agent, index) => (
            <MenuItem key={agent._id} value={agent}>
              {agent.firstName + " " + agent.lastName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
