import { FilterDropDown } from "../../../components/filter-dropdown";

export const TransactionReportFilter = ({ handleSubmit, data, handelInputChange, handleSelectChange, cancel }) => {
 
  const transactionType = [
    { label: 'Credit', value: 'credit' },
    { label: 'Debit', value: 'debit' },
  ]

  let page = []
  let i =40
  
  for(i; i  < 200; i = i +40 ){
     page.push( {label:i, value:i})
  }
  

  return (
    <form action="" onSubmit={handleSubmit}>
      <div className="transaction-type-section">
        <FilterDropDown options={page} label={'Page Range'} name={'page'} onSelect={handleSelectChange} />
        <br /> 
        <FilterDropDown options={transactionType} label={'type'} name={'type'} onSelect={handleSelectChange} />
        <br /><br />
       
        <div className="transaction-type-input-field">
          <div>
            <label htmlFor="name">Saver Name</label>
            <input className="inputs" type="text" name="name" onChange={handelInputChange} value={data?.name} />
          </div>
         
        </div>
        <div className="transaction-type-input-field">
          <div>
            <label htmlFor="from">From</label>
            <input className="inputs" type="date" name="start_date" onChange={handelInputChange} value={data?.start_date} />
          </div>
          <div>
            <label htmlFor="to">To</label>
            <input className="inputs" type="date" name="end_date" onChange={handelInputChange} value={data?.end_date} />
          </div>
        </div>

        {/* Amount */}
        <div className="transaction-type-input-field">
          <div>
            <label htmlFor="amount_from">Amount From</label>
            <input className="inputs" type="number" name="amount_from" onChange={handelInputChange} value={data?.amount_from} />
          </div>
          <div>
            <label htmlFor="amount_to">Amount To</label>
            <input className="inputs" type="number" name="amount_to" onChange={handelInputChange} value={data?.amount_to} />
          </div>
        </div>

        <div className="transaction-type-input-field">
          <button className="inputs" type="button" onClick={cancel} style={{cursor:'pointer'}}>Cancel</button>
          <button className="my-btn inputs" style={{cursor:"pointer"}}>Apply</button>
        </div>
      </div>
    </form>
  )
}
