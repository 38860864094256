import { FilterDropDown } from "../../../components/filter-dropdown";

export const AffiliateDataFilter = ({ handleSubmit,  handleSelectChange, cancel }) => {
  
  const status = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];

  let page = []
  let i =40
  
  for(i; i  < 200; i = i +40 ){
     page.push( {label:i, value:i})
  }

  return (
    <form action="" onSubmit={handleSubmit}>
      <div className="transaction-type-section">

        <FilterDropDown options={status} label={'Status'} name={'status'} onSelect={handleSelectChange} />
        <br />
        <div className="transaction-type-input-field">
          <button className="inputs" type="button" style={{cursor:'pointer'}} onClick={cancel}>Cancel</button>
          <button className="my-btn inputs btn" style={{cursor:'pointer'}}>Apply</button>
        </div>
      </div>
    </form>
  )
}
