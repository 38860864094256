import { useFormik } from "formik";
import { useEffect, useState } from "react";

// material
import {
  Container,
  Grid,
  Stack,
  styled,
  Typography,
  Card,
  Box,
  CircularProgress,
} from "@mui/material";
// components
import Page from "../components/Page";
import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar,
} from "../sections/@dashboard/products";
//
import PRODUCTS from "../_mocks_/products";
import RecentTransactions from "src/sections/@dashboard/app/RecentTransactions";
import ClientTable from "src/sections/@dashboard/app/ClientTable";
import DashBoardCard from "src/components/DashboardCard";
import AlertDialogSlide from "src/components/SlideInDialog";
import CreateAgentForm from "src/components/CreateAgentForm";
import CreateClientForm from "src/components/CreateClientForm";
import { apiFunctions } from "src/api";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  alignItems: "center",
  padding: theme.spacing(3, 5),
  backgroundColor: "white",
  height: theme.spacing(14),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",

  borderRadius: theme.spacing(20),
  alignItems: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: "center",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%)`,
}));

export default function Savers() {
  const userSelector = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState();
  const [savers, setSavers] = useState([]);
  const [totalSavers, setTotalSavers] = useState(0);
  const [showFormDialog, setShowFormDialog] = useState(false);

  const formik = useFormik({
    initialValues: {
      gender: "",
      category: "",
      colors: "",
      priceRange: "",
      rating: "",
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  const getSavers = async () => {
    try {
      setLoading(true);
      const res = await apiFunctions.getSavers(
        userSelector.user.data.superagent.id
      );
      setLoading(false);
      if (res.status) {
        setSavers(res.data);
        setTotalSavers(res.total);
        console.log("res top savers => ", res);
      } else {
        console.log(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getBalance = async () => {
    setLoading(true);
    try {
      const res = await apiFunctions.getBalance(
        userSelector.user.data.superagent.id
      );
      setLoading(false);
      if (res.status) {
        setBalance(res.data.total);
        console.log("res top balance => ", res);
      } else {
        console.log(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getSavers();
    getBalance();
  }, []);

  return (
    <Page title="Clients">
      <Container maxWidth="xl">
        <Grid container spacing={3} wrap="wrap">
          <Grid item xs={12} sm={6} md={3}>
            <DashBoardCard icon={"balance"} amount={balance} title="Balance" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashBoardCard
              icon={"agent-icon-colored"}
              other={totalSavers}
              title="Savers"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <RootStyle>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowFormDialog(!showFormDialog)}
              >
                <IconWrapperStyle>
                  <img
                    alt={"add-agent"}
                    src={`/static/icons/client-star.svg`}
                    width={25}
                    height={25}
                  />
                </IconWrapperStyle>

                <Typography
                  sx={{ color: "black", fontSize: "14px", my: "5px" }}
                >
                  Add New Saver
                </Typography>
              </Box>
            </RootStyle>
          </Grid>
        </Grid>
        <Grid sx={{ mt: 3 }}>
          {loading ? (
            <Grid item xs={12} md={12} lg={12}>
              <Stack
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 10,
                }}
              >
                <CircularProgress />
              </Stack>
            </Grid>
          ) : (
            <ClientTable client={savers} />
          )}
        </Grid>
        {showFormDialog && (
          <AlertDialogSlide
            isOpen={showFormDialog}
            isClose={() => setShowFormDialog(false)}
            dialogComponent={<CreateClientForm />}
          />
        )}
      </Container>
    </Page>
  );
}
