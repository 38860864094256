import { useEffect, useState } from "react";

import {
  Container,
  Grid,
  Stack,
  CircularProgress,
} from "@mui/material";

import { apiFunctions } from "src/api";
import Page from "src/components/Page";
import { AdminDashboardCommonData } from "./commons/admin-dashboard-common-data";
import AdminTransferLogsTable from "./tables/transfer-logs";


export default function AdminTransferLogs() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  
 

  const getPlans = async (page, rowsPerPage, query) => {
    try {
      setLoading(true);
      const res = await apiFunctions.getAdminTransferLogs(page, rowsPerPage, query);
      setData(res?.data);
      setTotalRows(res?.total);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

   

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  
  useEffect(() => {
    getPlans(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  return (
    <Page title="Clients">
      <Container maxWidth="xl">
        <AdminDashboardCommonData />
        <Grid sx={{ mt: 3 }}>
          {loading ? (
            <Grid item xs={12} md={12} lg={12}>
              <Stack
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 10,
                }}
              >
                <CircularProgress />
              </Stack>
            </Grid>
          ) : (
            <AdminTransferLogsTable
              transferLogs={data}
              totalRows={totalRows}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          )}
        </Grid>
          
      </Container>
    </Page>
  );
}
