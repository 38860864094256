import { FilterDropDown } from "../../../components/filter-dropdown";

export const TransactionPlanFilter = ({ handleSubmit, data, handelInputChange, handleSelectChange, cancel }) => {
  const transactionType = [
    { label: 'Earn', value: 'earn' },
    { label: 'Build', value: 'build' },
    { label: 'Keep', value: 'keep' },
    { label: 'Flex', value: 'flex' },
  ]

  const status = [
    { label: 'Completed', value: 'completed' },
    { label: 'Pending', value: 'pending' },
    { label: 'Active', value: 'active' },
    { label: 'Cancelled', value: 'cancelled' },
  ];

  let page = []
  let i =40
  
  for(i; i  < 200; i = i +40 ){
     page.push( {label:i, value:i})
  }

  return (
    <form action="" onSubmit={handleSubmit}>
      <div className="transaction-type-section">
        <FilterDropDown options={page} label={'Page Range'} name={'page'} onSelect={handleSelectChange} />
        <br /> 
        <FilterDropDown options={status} label={'Status'} name={'status'} onSelect={handleSelectChange} />
        <br /><br />
        <FilterDropDown options={transactionType} label={'Plans'} name={'plan'} onSelect={handleSelectChange} />
        <br />

        <div className="transaction-type-input-field">
          <div>
            <label htmlFor="from">From</label>
            <input className="inputs" type="date" name="start_date" onChange={handelInputChange} value={data?.start_date} />
          </div>
          <div>
            <label htmlFor="to">To</label>
            <input className="inputs" type="date" name="end_date" onChange={handelInputChange} value={data?.end_date} />
          </div>
        </div>

        <div className="transaction-type-input-field">
          <button className="inputs" type="button" style={{cursor:'pointer'}} onClick={cancel}>Cancel</button>
          <button className="my-btn inputs btn" style={{cursor:'pointer'}}>Apply</button>
        </div>
      </div>
    </form>
  )
}
