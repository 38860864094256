import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Table,
  Stack,
  Box,
  Avatar,
} from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
import { fShortenNumber } from "../../../utils/formatNumber";

//
import { BaseOptionChart } from "../../../components/charts";
import { UserListHead } from "../user";
import palette from "src/theme/palette";
import moment from "moment";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

const CHART_DATA = [4344, 5435, 1443, 4443];

export default function ClientTransactionTable({ transactions }) {
  const theme = useTheme();

  const TABLE_HEAD = [
    { id: "data", label: "Date", alignRight: false },
    { id: "amount", label: "Amount (N)", alignRight: false },
    { id: "trans_type", label: "Transaction Type", alignRight: false },
  ];

  return (
    <Card>
      <CardHeader title="Transactions" />

      <TableContainer>
        <Table>
          <UserListHead headLabel={TABLE_HEAD} rowCount={transactions.length} />
          <TableBody>
            {transactions.map((user) => {
              const { createdAt, amount, type, _id } = user;

              return (
                <TableRow hover key={_id} tabIndex={-1}>
                  <TableCell component="th" scope="row" padding="10">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
                        {moment(createdAt).format("ll")}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="left">{amount}</TableCell>

                  <TableCell align="left">{type}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
