import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { apiFunctions } from "src/api";
import DashBoardCard from "src/components/DashboardCard";
import { ExportIconSVG } from "src/components/ExportIconSVG";
import { FilterIconSVG } from "src/components/FilterIconSVG";

export const AffiliatesDashboardCommonData = ({ handleModal, exportData, affiliateModal, data }) => {
    const location = useLocation();


    return (
        <>
            <Grid container spacing={1} wrap="nowrap">
                <Grid item xs={3}>
                    <DashBoardCard icon={"agent-icon-colored"} other={data?.totalAffiliates} title="Total Affiliates" />
                </Grid>
                <Grid item xs={3}>
                    <DashBoardCard icon={"agent-icon-colored"} other={data?.activeAffiliates} title="Active Affiliates" />
                </Grid>
                <Grid item xs={3}>
                    <DashBoardCard icon={"agent-icon-colored"} other={data?.totalReferrals} title="Referrals" />
                </Grid>
                <Grid item xs={3}>
                    <DashBoardCard icon={"agent-icon-colored"} other={'  '} >
                        <button style={{ background: 'inherit', color: '#000000', border: 'none', cursor: 'pointer' }}
                            onClick={affiliateModal}
                        >
                            Create New Affiliate
                        </button>
                    </DashBoardCard>
                </Grid>
            </Grid>


            <Grid container spacing={4} wrap="nowrap">
                <Grid item xs={12} sm={8} md={9} className="plans-div">
                    <Link
                        to="/dashboard/admin/plans"
                        className={`plans ${location.pathname === '/dashboard/admin/plans' ? 'plans-active' : ''}`}
                    >
                        All Affiliates
                    </Link>
                </Grid>

                <Grid item xs={12} sm={4} md={3} container spacing={1} className="plans-div">
                    <Grid item xs={6}>
                        <div className="export" style={{ cursor: 'pointer' }} onClick={exportData}>
                            <button style={{ background: 'inherit', color: '#fff', border: 'none', cursor: 'pointer' }}>Export</button>
                            <ExportIconSVG />
                        </div>
                    </Grid>
                    {
                        location.pathname === '/dashboard/admin/customer/information' ? '' :


                            <Grid item xs={6}>
                                <div className="filter">
                                    <FilterIconSVG />
                                    <button style={{ background: 'inherit', color: '#000000', border: 'none', cursor: 'pointer' }}
                                        onClick={handleModal}
                                    >Filter</button>
                                </div>
                            </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
};
