import axios from "axios";
import BASE_URL from "../helpers/config";
// import { getData } from "./storage";

export const getToken = () => {
  let userToken = localStorage.getItem("token");
  return userToken;
};

export const apiFunctions = {
  login: async (fd) => postWithNoToken(`auth/signin`, fd),

  sendMessage: async (fd) => postWithToken(`message/send`, fd),

  createSuperAgent: async (fd) => postWithNoToken(`user/superagent`, fd),
  getDahboardSummary: async () => getWithToken(`user/superagent/dashboard`),
  getTopAgents: async () => getWithToken(`user/top-agents`),
  getTransactions: async () =>
    getWithToken(
      `transaction?fieldAgent&superAgent&populate=client fieldAgent superAgent`
    ),
  getFieldAgentSummary: async () => getWithToken(`user/fieldagent/summary`),
  getAgents: async () => getWithToken(`user/superagent/fieldagent`),
  getFATransactions: async (id) =>
    getWithToken(
      `transaction?fieldAgent=${id}&populate=client fieldAgent superAgent`
    ),
  getSaverTransactions: async (id) => getWithToken(`transaction?client=${id}`),

  getSavers: async (id) =>
    getWithToken(
      `user/clients?page=1&size=10&superAgent=${id}&populate=superAgent fieldAgent`
    ),

  getAminReportPlans: async (page = 1, size , query) => postWithToken(`admin-client/reports/plan?size=${size}&page=${page}`, query),

  getAminTransactionreport: async (page = 1, size, query) =>
    postWithToken(
      `admin-client/reports/wallet/transactions?size=${size}&page=${page}`, query
    ),

  getAminTransactionreportExport: async (page = null, size = null, query) =>
    getWithToken(
      `admin-client/reports/transaction/export?size=${size}&page=${page}`, query
    ),

  getAminCustomerInformationReport: async (page = 1, size = 25, query) =>
    postWithToken(
      `admin-client/reports/customer/information?size=${size}&page=${page}`, query
    ),

  getAdminTransferLogs: async (page = 1, size = 25, query) =>getWithToken( `account/transfer/logs?size=${size}&page=${page}`, query),

  getAminDashboardReport: async () => getWithToken(`admin-client/reports/dashboard`),

  getCheckoffSourceBankAccountBalance: async () => getWithToken(`account/balance`),
  makeWithdrawals: async (fd) => postWithToken(`account/withdrawals`, fd),
  
  // Verify me flow
  verifymeKYC: async (fd) => postWithToken(`kyc/verifyme`, fd),
  getverifymeKycs: async (page=1, size=25, query) => getWithToken(`kyc/verifyme/kycs?size=${size}&page=${page}`, query),
  

  // Reset PIN
  resetPin: async (fd) => postWithToken(`admin/recover/pin`, fd),


  addFieldAgent: async (fd) => postWithToken(`user/fieldagent`, fd),
  suspendFieldAgent: async (id) => postWithToken(`user/${id}/suspend`),
  deactivateFieldAgent: async (id) => postWithToken(`user/${id}/deactivate`),
  activateFieldAgent: async (id) => postWithToken(`user/${id}/activate`),
  transferAgent: async (fd) =>
    postWithToken(`user/fieldagent/transferclient`, fd),
  getAllFieldAgents: async () => getWithToken(`user/fieldagent`),
  increaseLimit: async (id, fd) => putWithToken(`user/accountlimit/${id}`, fd),
  getBalance: async (id) => getWithToken(`user/balance?superAgent=${id}`),
  addSaver: async (fd) => postWithToken(`user/client/superagent`, fd),
  getTransSummary: async () => getWithToken(`transaction/summary`),
  transferClientsToFA: async (fd) =>
    postWithToken(`user/fieldagent/transferclients`, fd),

  downloadExcelFile: async (endpoint, filename, page = null, size = null, query = null) => {
    try {
      const response = await axios.get(`${BASE_URL}${endpoint}`, {
        responseType: 'blob',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        params: {
          query: query,
          size,
          page
        }
      });

      // Create a URL for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // Set the file name for the download
      link.setAttribute('download', `${filename}.xlsx`);

      // Append the link and click to download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  },

};

// const getWithTokenClean = async (path) => {
//   let userToken = await getData('userToken');
//   try {
//     await fetch(`${BASE_URL}${path}`, {
//       method: 'get',
//       headers: new Headers({
//         Authorization: `${userToken}`,
//         'Content-Type': 'application/json'
//       })
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         return data;
//       });
//   } catch (error) {
//     console.log(error);
//   }
// };

const getWithToken = async (path) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}${path}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  } catch (error) {
    console.log("get error => ", error);
    return error;
  }
};

const postWithToken = (path, fd) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const { data } = await axios.post(`${BASE_URL}${path}`, fd, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });

        resolve(data);
      } catch (err) {
        const error = err.response ? err.response.data : err;

        console.log("error => ", error);
        reject(error);
      }
    })();
  });

const putWithToken = async (path, fd) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URL}${path}`, fd, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          console.log("error => ", error);
          return error;
        });
    });
  } catch (error) {
    console.log({ error });
  }
};

const deleteWithToken = async (path) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URL}${path}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          console.log("error => ", error);
          return error;
        });
    });
  } catch (error) {
    console.log({ error });
    return error;
  }
};

// const putWithToken = async (path, fd) => {
//   try {
//     let userToken = await getData('userToken');

//     console.log('url', `${BASE_URL}${path}`);
//     return new Promise((resolve, reject) => {
//       axios
//         .put(`${BASE_URL}${path}`, fd, {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `${userToken}`
//           }
//         })
//         .then((result) => {
//           resolve(result.data);
//         })
//         .catch((error) => {
//           reject({
//             status: 500,
//             // msg: error.response.data.message[0].messages[0].message,
//             msg: error.response.data
//           });
//         });
//       setTimeout(
//         () =>
//           reject({
//             status: 500,
//             message: 'Connection Error. Please try again later'
//           }),
//         18000
//       );
//     });
//   } catch (error) {
//     console.log({ error });
//   }
// };

const postWithNoToken = (path, fd) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}${path}`, fd, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        // console.log(error);
        console.log(error.response);
        if (!error.response.data.isAuth) {
          alert(error.response.data.message);
        }
        return error;
      });
  });
};




