import { Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import DashBoardCard from "src/components/DashboardCard";

import ProfileCard from "src/components/ProfileCard";

export const AffiliatesReferralDashboardCommonData = ({ data, user}) => {
    const location = useLocation();

    return (
        <>
            <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', minHeight: '7rem' }}>
                <ProfileCard data={data?.affiliate} user={user} />
                <Grid item xs={6} sm={6} md={4} style={{ marginTop: '-10px' }}>
                    <DashBoardCard icon={"agent-icon-colored"} other={" "} title="Commision" >
                        <p>{data?.commissions}</p>
                    </DashBoardCard>
                    <br />
                    <DashBoardCard icon={"agent-icon-colored"} other={" "} title="Referrals" >
                        <p> {data?.savers?.length}</p>
                    </DashBoardCard>
                </Grid>

            </Grid>

            <Grid container spacing={4} wrap="nowrap">
                <Grid item xs={12} sm={8} md={9} className="plans-div">
                    <Link
                        to={`/dashboard/affiliates/${data?.affiliate?.affiliateCode}/referrals`}
                        className={`plans ${location.pathname === `/dashboard/affiliates/${data?.affiliate?.affiliateCode}/referrals` ? 'plans-active' : ''}`}
                    >
                        Referrals
                    </Link>
                    <Link
                        to="/dashboard/admin/transactions"
                        className={`plans ${location.pathname === '/dashboard/admin/transactions' ? 'plans-active' : ''}`}
                    >
                        Transactions
                    </Link>

                </Grid>
            </Grid>
        </>
    );
};
