import * as Yup from "yup";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  Button,
  Container,
  Typography,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { alpha, styled } from "@mui/material/styles";
import palette from "src/theme/palette";
// component
import Iconify from "../components/Iconify";
import PrimaryButton from "src/components/PrimaryButton";
import CustomSelector from "./CustomSelector";
import AlertDialogSlide from "./SlideInDialog";
import AttachClientDialog from "./AttachDialog";
import { apiFunctions } from "src/api";
import FieldAgentSelector from "./FieldAgentSelector";
import {
  setMessage,
  setShowToast,
  setSuccess,
} from "src/redux/slice/appLoaderSlice";
import { useDispatch } from "react-redux";

// ----------------------------------------------------------------------

const ButtonWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  borderRadius: "8px",
  border: "1px solid #1C2ECE",
  borderStyle: "dashed",
  alignItems: "center",
  paddingLeft: "30px",
  paddingRight: "30px",
  //   paddingTop: "10px",
  //   paddingBottom: "10px",
  justifyContent: "center",
  color: theme.palette.primary.contrastText,
  backgroundImage: "transparent",
}));

export default function CreateClientForm() {
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState({});
  const [location, setLocation] = useState("");
  const [trade, setTrade] = useState("");
  const [depositFrequency, setDepositFrequency] = useState("");
  const [withdrawalFrequency, setWithdrawalFrequency] = useState("");
  const dispatch = useDispatch();
  const dateRef = useRef(null);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      style={{
        border: "none",
        height: 50,
        width: "100%",
        borderRadius: 10,
      }}
      onClick={onClick}
      ref={ref}
    >
      <Typography sx={{ fontSize: "14px", color: "rgb(0,0,0)", opacity: 0.5 }}>
        Deposit Start Date 👉 {value}
      </Typography>
    </button>
  ));

  const CreateClientSchema = Yup.object().shape({
    fullName: Yup.string().required("Fullname is required"),
    phoneNumber: Yup.number().required("Phone number is required"),
    contributionAmount: Yup.number().required(
      "Contribution Amount is required"
    ),
  });

  const resetState = () => {
    dispatch(setShowToast(false));
    dispatch(setMessage(""));
    dispatch(setSuccess(null));
  };

  const addSaver = async (payload) => {
    resetState();
    setLoading(true);
    console.log(payload);
    try {
      const res = await apiFunctions.addSaver(payload);
      if (res.status) {
        dispatch(setShowToast(true));
        dispatch(setSuccess(true));
        dispatch(setMessage(res.message));
      } else {
        dispatch(setShowToast(true));
        dispatch(setSuccess(false));
        dispatch(setMessage(res.message));
      }
      dispatch(setShowToast(false));
    } catch (e) {
      console.log(e);
      // resetState();
      // updateState();
      dispatch(setShowToast(true));
      dispatch(setSuccess(false));
      dispatch(setMessage(e.error));
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      contributionAmount: "",
    },
    validationSchema: CreateClientSchema,
    onSubmit: (e) => {
      e.preventDefault();
      location &&
      trade &&
      depositFrequency &&
      withdrawalFrequency &&
      selectedAgent
        ? addSaver({
            ...values,
            location: location,
            trade: trade,
            depositFrequency: depositFrequency,
            withdrawalFrequency: withdrawalFrequency,
            depositStartDate: startDate,
            fieldAgent: selectedAgent._id,
            contributionAmount: +values.contributionAmount,
          })
        : alert("fill all fields");
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <Container sx={{ width: "450px", py: 3 }}>
      <Typography sx={{ fontWeight: "bold", fontSize: "16px", mb: 3 }}>
        Create Saver
      </Typography>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Stack spacing={2} sx={{ mb: 3 }}>
            <TextField
              fullWidth
              autoComplete="fullName"
              type="name"
              label="Full Name (same as Bank Account Name)"
              {...getFieldProps("fullName")}
              error={Boolean(touched.fullName && errors.fullName)}
              helperText={touched.fullName && errors.fullName}
            />
            <CustomSelector
              selectData={[
                { id: 0, name: "Lagos" },
                { id: 1, name: "Abuja" },
                { id: 1, name: "Calabar" },
              ]}
              title="Select Location"
              setSelect={(e) => setLocation(e)}
            />
            <TextField
              fullWidth
              autoComplete="phoneNumber"
              type="phone"
              label="Phone Number"
              {...getFieldProps("phoneNumber")}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
            />
            <CustomSelector
              selectData={[
                { id: 0, name: "Pepper Seller" },
                { id: 1, name: "Tailor" },
                { id: 1, name: "Rentals" },
              ]}
              title="Select Trade"
              setSelect={(e) => setTrade(e)}
            />
            <TextField
              fullWidth
              autoComplete="number"
              type="number"
              label="Contribution Amount"
              {...getFieldProps("contributionAmount")}
              error={Boolean(
                touched.contributionAmount && errors.contributionAmount
              )}
              helperText={
                touched.contributionAmount && errors.contributionAmount
              }
            />
            <CustomSelector
              selectData={[
                { id: 0, name: "daily" },
                { id: 1, name: "weekly" },
                { id: 1, name: "monthly" },
              ]}
              title="Deposit Frequency"
              setSelect={(e) => setDepositFrequency(e)}
            />
            <CustomSelector
              selectData={[
                { id: 0, name: "daily" },
                { id: 1, name: "weekly" },
                { id: 1, name: "monthly" },
              ]}
              title="Withdrawal Frequency"
              setSelect={(e) => setWithdrawalFrequency(e)}
            />
            <DatePicker
              showIcon
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              customInput={<ExampleCustomInput />}
              dateFormat={"yyyy-MM-dd"}
            />
            <Box sx={{ my: "30px" }}>
              <FieldAgentSelector selectedAgent={(e) => setSelectedAgent(e)} />
            </Box>
            {/* <Button variant="outlined" component="label">
              Upload Image
              <input hidden accept="image/*" multiple type="file" />
            </Button> */}
          </Stack>

          <PrimaryButton
            buttonText={"Create Saver"}
            loading={loading}
            onClick={() =>
              addSaver({
                ...values,
                location: location,
                trade: trade,
                depositFrequency: depositFrequency,
                withdrawalFrequency: withdrawalFrequency,
                depositStartDate: startDate,
                fieldAgent: selectedAgent._id,
                contributionAmount: +values.contributionAmount,
              })
            }
          />
        </Form>
      </FormikProvider>
      {showTransferDialog && (
        <AlertDialogSlide
          dialogComponent={
            <AttachClientDialog
              client_name="Akerele Lagbaja"
              isClose={() => setShowTransferDialog(false)}
            />
          }
          isOpen={showTransferDialog}
          isClose={() => setShowTransferDialog(false)}
        />
      )}
    </Container>
  );
}
