// material
import {
    Card,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Table,
    Stack,
    Pagination,
    Grid
} from "@mui/material";
import GeneralHeader from "src/sections/@dashboard/user/GeneralHeader";
import { useState } from "react";
import { capitalizeFirstLetter, formatDate, formatTime } from "src/utils/formatTime";
import TablePaginationActions from "../commons/table-pagination-action-component";

export default function VerifymeKYCsTable({ plans, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, totalRows }) {
    const TABLE_HEAD = [
        { id: "firstname", label: " First Name", alignRight: false },
        { id: "lastname", label: " Last Name", alignRight: false },
        { id: "date", label: " Date Of Birth", alignRight: false },
        { id: "phone", label: "Pone Number", alignRight: false },
        { id: "bvn", label: " BVN", alignRight: false },
        { id: "gender", label: " Gender", alignRight: false },
        { id: "photo", label: " Account Picture", alignRight: false },
    ];

    

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };


    const handlePaginationChange = (event, value) => {
        handleChangePage(event, value - 1); // Material-UI Pagination uses 1-based index
    };

    const sortedData = [...plans].sort((a, b) => {
        
        return 0;
    });

    return (
        <Card>
            <TableContainer>
                <Table>
                    <GeneralHeader headLabel={TABLE_HEAD} rowCount={plans?.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                    <TableBody>
                        {sortedData.map((kyc) => {
                            const {
                                birthdate,
                                firstname,
                                lastname,
                                phone,
                                bvn,
                                photo,
                                gender,
                                _id,
                            } = kyc;

                            return (
                                <TableRow hover key={_id} tabIndex={-1}>
                                    <TableCell align="left"> { capitalizeFirstLetter( firstname)}</TableCell>
                                    <TableCell align="left"> { capitalizeFirstLetter( lastname)}</TableCell>
                                    <TableCell align="left"> { birthdate}</TableCell>
                                    <TableCell align="left"> { phone}</TableCell>
                                    <TableCell align="left"> { bvn}</TableCell>
                                    <TableCell align="left"> { gender}</TableCell>
                                    <TableCell align="left">
                                        <img src={`data:image/jpeg;base64,${photo}`} alt="BVN Image" style={{width:'4rem', height:'4rem'}} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                    <TablePagination
                        rowsPerPageOptions={[20, 30, 40, 50, 60, 70, 80, 90,100, 200, 300, 400, 500, 600, 700, 10000]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions} // Use the custom component
                        
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Pagination
                            count={Math.ceil(totalRows / rowsPerPage)}
                            page={page + 1} // Material-UI Pagination uses 1-based index
                            onChange={handlePaginationChange}
                            color="primary"
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    );
}
