import { IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import AgentOptions from "./AgentOptions";
import Iconify from "./Iconify";
import MenuPopover from "./MenuPopover";

export default function ShowHideOption({ data }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <IconButton size="small" ref={anchorRef} onClick={handleOpen}>
        <Iconify icon="simple-line-icons:options" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <AgentOptions data={data} />
      </MenuPopover>
    </div>
  );
}
