export const Loader = () => {
    return (
      <div style={{ marginTop: "5px", fontSize: "12px", color: "#007bff" }}>
        <span className="loader"></span> Validating phone number...
        <style>
          {`
            .loader {
              display: inline-block;
              width: 10px;
              height: 10px;
              border: 2px solid #007bff;
              border-top: 2px solid transparent;
              border-radius: 50%;
              animation: spin 0.6s linear infinite;
              margin-right: 5px;
            }
  
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      </div>
    );
  };
  