import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { apiFunctions } from "src/api";
import { AdjustableModal } from "../commons/adjustable-modal";
import DashBoardCard from "src/components/DashboardCard";
import Page from "src/components/Page";
import UpdateBankForm from "./forms/update-bank-data";
import { resetObject } from "src/utils/general";
import { toast } from "react-toastify";
import ResetPinForm from "./forms/reset-pin";
import OtherFieldUpdateForm from "./forms/update-other-fields";


export const UsermanagerHeader = () => {
    const location = useLocation();
    const [data, setData] = useState(null);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)


    const [bankUpdateModal, setBankUpdateModal] = useState('hide-modal')

    const [bankformData, setBankFormData] = useState({
        bvn: '',
        bankId: '',
        accountNumber: ''
    })


    const [resetPinModal, setResetPinModal] = useState('hide-modal')

    const [resetPinData, setResetPinData] = useState({
        email: '',
        pin: '',
    })


    const [otherFieldModal, setotherFieldModal] = useState('hide-modal')

    const [otherFieldData, setOtherFieldData] = useState({
        field: '',
        value: '',
        bvn: ''
    })


    // Bank details operations


    const getBank = async () => {
        const res = await apiFunctions.getBanks();
        setData(res.data);
    }

    const closeUpdateBankModal = () => {

        setBankUpdateModal('hide-modal');
    }


    const handleBankUpdateInputChange = async (e) => {
        setMessage('')
        const { name, value } = e.target
        setBankFormData({
            ...bankformData,
            [name]: value,
        });
    };

    const handleBankModalOpen = () => {
        setMessage('')
        setBankUpdateModal('show-modal');
    }


    const submitbankUpdate = async (e) => {
        e.preventDefault();
        setMessage('')
        const { bvn, bankId, accountNumber } = bankformData;

        // Validate required fields
        if (!bankId | !bvn || !accountNumber) {
            const msg = "Please supply all required fields";
            setMessage(msg);
            setIsLoading(false);
            setIsError(true);
            toast.error(msg);
            return;
        }

        try {

            setIsLoading(true); // Indicate loading state

            const updateResponse = await apiFunctions.updateBankAccount(bankformData)

            closeUpdateBankModal()

            setBankFormData(resetObject(bankformData))

            setMessage(updateResponse?.message || "Bank Account Updated");

            setIsError(false);
        } catch (error) {
            setMessage(error?.message);
            setIsError(true);
            return
        } finally {
            setIsLoading(false);
        }
    };


    // Pin reset operations
    const closeResetModal = () => {
        setResetPinModal('hide-modal');
    }


    const handleResetPinInputChange = async (e) => {
        setMessage('')
        const { name, value } = e.target
        setResetPinData({
            ...resetPinData,
            [name]: value,
        });
    };

    const handleResetPinModalOpen = () => {
        setMessage('')
        setResetPinModal('show-modal');
    }


    const submitResetPinForm = async (e) => {
        e.preventDefault();
        setMessage('')
        const { pin, email } = resetPinData;

        // Validate required fields
        if (!pin | !email) {
            const msg = "Please supply all required fields";
            setMessage(msg);
            setIsLoading(false);
            setIsError(true);
            toast.error(msg);
            return;
        }

        try {

            setIsLoading(true); // Indicate loading state

            const updateResponse = await apiFunctions.resetPin(resetPinData)

            closeResetModal()
            setResetPinData(resetObject(bankformData))

            setMessage(updateResponse?.message || "Pin Reset Successfully");

            setIsError(false);
        } catch (error) {
            setMessage(error?.message);
            setIsError(true);
            return
        } finally {
            setIsLoading(false);
        }
    };


    // Other fields update  operations
    const closeOtherFieldModal = () => {
        setotherFieldModal('hide-modal');
    }


    const handleOtherFieldInputChange = async (e) => {
        setMessage('')
        const { name, value } = e.target
        setOtherFieldData({
            ...otherFieldData,
            [name]: value,
        });
    };

    const handleOtherFieldModalOpen = () => {
        setMessage('')
        setotherFieldModal('show-modal');
    }


    const submitOtherFieldForm = async (e) => {
        e.preventDefault();
        setMessage('')
        const { field, value, bvn } = otherFieldData;

        // Validate required fields
        if (!field | !value || !bvn) {
            const msg = "Please supply all required fields";
            setMessage(msg);
            setIsLoading(false);
            setIsError(true);
            toast.error(msg);
            return;
        }

        try {

            setIsLoading(true); // Indicate loading state

            const updateResponse = await apiFunctions.updateOtherField(otherFieldData)

            closeResetModal()
            setResetPinData(resetObject(bankformData))

            setMessage(updateResponse?.message || "Field updated successfully");

            setIsError(false);
        } catch (error) {
            setMessage(error?.message);
            setIsError(true);
            return
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        getBank();
    }, []);
    return (
        <>

            <Page title="Client manager">
                <Container maxWidth='xl'>
                    <Grid container spacing={4} wrap="nowrap">
                        <Grid item xs={12} sm={8} md={9} className="plans-div">
                            <DashBoardCard icon={"agent-icon-colored"} other={'  '} >
                                <button style={{ background: 'inherit', color: '#000000', border: 'none', cursor: 'pointer' }}
                                    onClick={handleBankModalOpen}
                                >
                                    Manage Banks Update
                                </button>
                            </DashBoardCard>
                        </Grid>
                        <Grid item xs={12} sm={8} md={9} className="plans-div">
                            <DashBoardCard icon={"agent-icon-colored"} other={'  '} >
                                <button style={{ background: 'inherit', color: '#000000', border: 'none', cursor: 'pointer' }}
                                    onClick={handleOtherFieldModalOpen}
                                >
                                    Manage Account Data
                                </button>
                            </DashBoardCard>
                        </Grid>
                        <Grid item xs={12} sm={8} md={9} className="plans-div">
                            <DashBoardCard icon={"agent-icon-colored"} other={'  '} >
                                <button style={{ background: 'inherit', color: '#000000', border: 'none', cursor: 'pointer' }}
                                    onClick={handleResetPinModalOpen}
                                >
                                    Reset Wallet Pin
                                </button>
                            </DashBoardCard>
                        </Grid>

                    </Grid>

                    <AdjustableModal title={'Update Client Bank Data'} modalDisplayClass={bankUpdateModal}
                        onClose={closeUpdateBankModal}>
                        <UpdateBankForm
                            onCancel={closeUpdateBankModal}
                            formData={bankformData}
                            banks={data}
                            handleChange={handleBankUpdateInputChange}
                            handleSubmit={submitbankUpdate}
                            isError={isError}
                            loading={isLoading}
                            message={message}
                        />
                    </AdjustableModal>

                    <AdjustableModal title={'Reset Wallet Pin'} modalDisplayClass={resetPinModal}
                        onClose={closeResetModal}>
                        <ResetPinForm
                            onCancel={closeResetModal}
                            formData={resetPinData}
                            handleChange={handleResetPinInputChange}
                            handleSubmit={submitResetPinForm}
                            isError={isError}
                            loading={isLoading}
                            message={message}
                        />
                    </AdjustableModal>

                    <AdjustableModal title={'Update Other Fields'} modalDisplayClass={otherFieldModal}
                        onClose={closeOtherFieldModal}>
                        <OtherFieldUpdateForm
                            onCancel={closeOtherFieldModal}
                            formData={otherFieldData}
                            handleChange={handleOtherFieldInputChange}
                            handleSubmit={submitOtherFieldForm}
                            isError={isError}
                            loading={isLoading}
                            message={message}
                        />
                    </AdjustableModal>

                </Container>
            </Page>

        </>
    );
};
