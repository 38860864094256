
import { apiFunctions } from "src/api";

import { useEffect, useState } from "react";
import { ScreenLoader } from "src/components/Screen-Loader";
import { ProcessVerification } from "./process-verification";
import VerifymeKycData from "./verifyme-data";

// ----------------------------------------------------------------------

export default function VerifymeKYC() {
    const [isLoading, setIsLoading] = useState(false)

    const [message, setMessage] = useState('')
    const [isError, setIsError] = useState(false)
    const [formData, setFormData] = useState({
        bvn: ''
    })


    const handleInputChange = (e) => {
        setMessage('')
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')
        try {

            if (!formData.bvn || formData.bvn?.length !== 11) {
                const msg = "BVN must be exactly 10 character"
                setMessage(msg)
                setIsLoading(false);
                setIsError(true)
            } else {

                const res = await apiFunctions.verifymeKYC({
                    bvn: formData.bvn,
                });

                if (res.status) {
                    setFormData({
                        bvn: "",
                    })
                     window.location.reload()
                }
                setMessage(res?.message)
                setIsLoading(false)
                setIsError(false)
            }
        } catch (e) {
            setIsLoading(false)
            setMessage(e?.message)
            setIsError(true)
        }
    }


    return (
        <>
            <ScreenLoader status={isLoading} />
            <ProcessVerification
                handleSubmit={handleFormSubmit}
                handelInputChange={handleInputChange}
                data={formData}
                isSubmitting={isLoading}
                message={message}
                isError={isError}
            />
            <hr style={{marginTop: '20px'}}/>
            <VerifymeKycData></VerifymeKycData>

        </>
    );
}

