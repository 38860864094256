import { useEffect, useState } from "react";
// material
import {
  Card,
  Container,
  Typography,
  Grid,
  Box,
  Slide,
  Stack,
  CircularProgress,
} from "@mui/material";
// components
import Page from "../components/Page";
import DashBoardCard from "src/components/DashboardCard";
import AlertDialogSlide from "src/components/SlideInDialog";
import styled from "@emotion/styled";
import AllAgents from "src/sections/@dashboard/app/AllAgentsTable";
import CreateAgentForm from "src/components/CreateAgentForm";
import { apiFunctions } from "src/api";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  alignItems: "center",
  padding: theme.spacing(3, 5),
  backgroundColor: "white",
  height: theme.spacing(14),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",

  borderRadius: theme.spacing(20),
  alignItems: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: "center",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%)`,
}));

// ----------------------------------------------------------------------

export default function Agents() {
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [agents, setAgents] = useState([]);
  const [showFormDialog, setShowFormDialog] = useState(false);

  const getFASummary = async () => {
    try {
      setLoading(true);
      const res = await apiFunctions.getFieldAgentSummary();
      setLoading(false);
      if (res.status) {
        setSummary(res.data);
        console.log("res summary => ", res);
      } else {
        console.log(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getAgents = async () => {
    try {
      setLoading(true);
      const res = await apiFunctions.getAgents();
      setLoading(false);
      if (res.status) {
        setAgents(res.data);
        console.log("res agents => ", res);
      } else {
        console.log(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getFASummary();
    getAgents();
  }, []);

  return (
    <Page title="Agents">
      <Container maxWidth="xl">
        <Grid container spacing={3} wrap="wrap">
          <Grid item xs={12} sm={6} md={3}>
            <DashBoardCard
              icon={"agent-icon-colored"}
              amount={summary.totalAgents}
              title="Total Agents"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashBoardCard
              icon={"client-plus"}
              amount={summary.activeAgents}
              title="Active Agents"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <RootStyle>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowFormDialog(!showFormDialog)}
              >
                <IconWrapperStyle>
                  <img
                    alt={"add-agent"}
                    src={`/static/icons/client-star.svg`}
                    width={25}
                    height={25}
                  />
                </IconWrapperStyle>

                <Typography
                  sx={{ color: "black", fontSize: "14px", my: "5px" }}
                >
                  Create New Agent
                </Typography>
              </Box>
            </RootStyle>
          </Grid>
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <Grid item>
            {loading ? (
              <Grid item xs={12} md={12} lg={12}>
                <Stack
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 10,
                  }}
                >
                  <CircularProgress />
                </Stack>
              </Grid>
            ) : (
              <AllAgents allAgents={agents} />
            )}
          </Grid>
        </Grid>
        {showFormDialog && (
          <AlertDialogSlide
            isOpen={showFormDialog}
            isClose={() => setShowFormDialog(false)}
            dialogComponent={
              <CreateAgentForm isClose={() => setShowFormDialog(false)} />
            }
          />
        )}
      </Container>
    </Page>
  );
}
