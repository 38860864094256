
import { apiFunctions } from "src/api";

import { useEffect, useState } from "react";
import { ProcessWithdrawals } from "../commons/process-withdrawals";
import { ScreenLoader } from "src/components/Screen-Loader";

// ----------------------------------------------------------------------

export default function WithdrawBalance() {
    const [isLoading, setIsLoading] = useState(false)

    const [message, setMessage] = useState('')
    const [isError, setIsError] = useState(false)
    const [formData, setFormData] = useState({
        amount: '',
        naration:''
    })

    const [balance, setBalance] = useState(0)

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')
        try {

            if (!formData.amount || formData.amount < 200) {
                const msg = "Amount must not be less than 200"
                setMessage(msg)
                setIsLoading(false);
                setIsError(true)
            } else {

                const res = await apiFunctions.makeWithdrawals({
                    amount: formData.amount,
                });

                if (res.status) {
                    setFormData({
                        amount: "",
                    })
                }
                setMessage(res?.message)
                setIsLoading(false)
                setIsError(false)
            }
        } catch (e) {
            setIsLoading(false)
            setMessage(e?.message)
            setIsError(true)
        }
    }

    const getAccountBalance = async () => {
        const res = await apiFunctions.getCheckoffSourceBankAccountBalance();
        setBalance(res.data);
    };

   
    useEffect(() => {
        getAccountBalance();

    }, []);

    return (
        <>
            <ScreenLoader status={isLoading} />
            <ProcessWithdrawals
                handleSubmit={handleFormSubmit}
                handelInputChange={handleInputChange}
                data={formData}
                isSubmitting={isLoading}
                balance={balance}
                message={message}
                isError={isError}
            />

        </>
    );
}

