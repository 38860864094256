import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Stack } from "@mui/material";
// utils
import { fShortenNumber } from "../../src/utils/formatNumber";
// component
import Iconify from "../components/Iconify";
import LoadingIndicator from "./LoadingIndicator";

const ButtonWrapperStyle = styled("div")(({ theme, bg }) => ({
  display: "flex",
  cursor: "pointer",
  borderRadius: "8px",
  alignItems: "center",
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "10px",
  paddingBottom: "10px",
  justifyContent: "center",
  color: theme.palette.primary.contrastText,
  backgroundImage: bg ? `linear-gradient(180deg, ${bg} 0%, ${bg} 100%)` : `linear-gradient(180deg, #7F055F 0%, #5941A9 100%)`,
  marginLeft: "10px",
  marginRight: "10px",
}));

export default function PrimaryButton({ buttonText, loading, icon, onClick, background }) {
  return (
    <ButtonWrapperStyle bg={background} onClick={onClick}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>{buttonText}</Typography>
          {icon && (
            <Iconify
              icon={icon}
              sx={{ width: "20px", height: "20px", mx: "5px" }}
            />
          )}
        </Stack>
      )}
    </ButtonWrapperStyle>
  );
}
