import { merge } from "lodash";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Table,
  Stack,
  Box,
  Avatar,
} from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
import { fShortenNumber } from "../../../utils/formatNumber";

//
import { BaseOptionChart } from "../../../components/charts";
import { UserListHead } from "../user";
import palette from "src/theme/palette";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

export default function ClientTable({ client }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const TABLE_HEAD = [
    { id: "image", label: "", alignRight: false },
    { id: "name", label: "Savers", alignRight: false },
    { id: "phone", label: "Phone Number", alignRight: false },
    { id: "amount", label: "Balance", alignRight: false },
    { id: "agent", label: "Trade", alignRight: false },
    { id: "trans_type", label: "Location", alignRight: false },
  ];

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <CardHeader title="Savers" />
        {/* <Box
          sx={{
            pt: 3,
            pr: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: palette.primary.main,
              decoration: "underline",
            }}
          >
            see all transactions
          </Typography>
        </Box> */}
      </Stack>

      <TableContainer>
        <Table>
          <UserListHead headLabel={TABLE_HEAD} rowCount={client?.length} />
          <TableBody>
            {client.map((user) => {
              const {
                balance,
                fullName,
                phoneNumber,
                location,
                image,
                trade,
                _id,
              } = user;

              return (
                <TableRow hover key={_id} tabIndex={-1}>
                  <TableCell component="th" scope="row" padding="10">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Avatar
                        src={null}
                        sx={{ width: 25, height: 25, cursor: "pointer" }}
                        onClick={() =>
                          navigate("/dashboard/clientProfile", {
                            replace: false,
                            state: user,
                          })
                        }
                      />
                    </Stack>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="10">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/dashboard/clientProfile", {
                            replace: false,
                            state: user,
                          })
                        }
                      >
                        {fullName}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="left">{phoneNumber}</TableCell>
                  <TableCell align="left">{balance}</TableCell>
                  <TableCell align="left">{trade}</TableCell>
                  <TableCell align="left">{location}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
