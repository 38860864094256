import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  setMessage,
  setShowToast,
  setSuccess,
} from "src/redux/slice/appLoaderSlice";

const ToastComp = () => {
  const appState = useSelector((state) => state.appLoader);
  const dispatch = useDispatch();
  const notify = () =>
    appState.state.success === true
      ? toast.success(appState.state.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      : appState.state.success === false
      ? toast.error(appState.state.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      : null;

  useEffect(() => {
    appState.state.showToast && appState.state.message !== "" && notify();
    // dispatch(setShowToast(false));
    // dispatch(setSuccess(null));
    // dispatch(setMessage(""));
  }, [
    appState.state.showToast,
    appState.state.message,
    appState.state.success,
  ]);

  return (
    <div>
      {appState.state.success === true ? (
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      ) : appState.state.success === false ? (
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ToastComp;
