import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { apiFunctions } from "src/api";
import DashBoardCard from "src/components/DashboardCard";
import { ExportIconSVG } from "src/components/ExportIconSVG";
import { FilterIconSVG } from "src/components/FilterIconSVG";
import { fNumber } from "src/utils/formatNumber";

export const AdminDashboardCommonData = ({ handleModal, exportData }) => {
    const location = useLocation();
    const [data, setData] = useState(null);
    const [balance, setBalance] = useState(null);

    const getDashboardData = async () => {
        const res = await apiFunctions.getAminDashboardReport();
        setData(res.data);
    };

    const getAccountBalance = async () => {
        const res = await apiFunctions.getCheckoffSourceBankAccountBalance();
        setBalance(res.data);
    };

    useEffect(() => {
        getDashboardData();
        getAccountBalance()
    }, []);
    return (
        <>
            <Grid container spacing={4} wrap="wrap">
                <Grid item xs={12} sm={6} md={4}>
                    <DashBoardCard icon={"agent-icon-colored"} other={data?.plans} title="Active plans" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashBoardCard icon={"balance"} amount={data?.balance} title="Total Balance" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashBoardCard icon={"balance"} amount={data?.interest} title="Interest Balance" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashBoardCard icon={"balance"} amount={data?.availableBalance} title="Withdrawable Balance" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashBoardCard icon={"balance"} other={`N${fNumber(balance?.balance)}`} title="Source Bank Account Balance" >
                        <div className="link-div">

                            <Link to='/dashboard/withdrawals' className="link-url" style={{ marginTop: '10px', textAlign: "left" }}>Transfer</Link>

                            <Link to='/dashboard/transfer/logs' className="link-url" style={{ marginTop: '10px', textAlign: "left", color:"green" }}>View logs</Link>
                        </div>
                    </DashBoardCard>
                </Grid>
            </Grid>

            <Grid container spacing={4} wrap="nowrap">
                <Grid item xs={12} sm={8} md={9} className="plans-div">
                    <Link
                        to="/dashboard/admin/plans"
                        className={`plans ${location.pathname === '/dashboard/admin/plans' ? 'plans-active' : ''}`}
                    >
                        Plans
                    </Link>
                    <Link
                        to="/dashboard/admin/transactions"
                        className={`plans ${location.pathname === '/dashboard/admin/transactions' ? 'plans-active' : ''}`}
                    >
                        Transactions
                    </Link>
                    <Link
                        to="/dashboard/admin/customer/information"
                        className={`plans ${location.pathname === '/dashboard/admin/customer/information' ? 'plans-active' : ''}`}
                    >
                        Customer Info
                    </Link>
                </Grid>

                <Grid item xs={12} sm={4} md={3} container spacing={1} className="plans-div">
                    <Grid item xs={6}>
                        <div className="export" style={{ cursor: 'pointer' }} onClick={exportData}>
                            <button style={{ background: 'inherit', color: '#fff', border: 'none', cursor: 'pointer' }}>Export</button>
                            <ExportIconSVG />
                        </div>
                    </Grid>
                    {
                        location.pathname === '/dashboard/admin/customer/information' ? '' :


                            <Grid item xs={6}>
                                <div className="filter">
                                    <FilterIconSVG />
                                    <button style={{ background: 'inherit', color: '#000000', border: 'none', cursor: 'pointer' }}
                                        onClick={handleModal}
                                    >Filter</button>
                                </div>
                            </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
};
