import React from "react";

import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Box, Stack } from "@mui/material";
// utils
import { fShortenNumber } from "../../src/utils/formatNumber";
// component
import Iconify from "../components/Iconify";
import LoadingIndicator from "./LoadingIndicator";

const ButtonWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  borderRadius: "8px",
  alignItems: "center",
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "10px",
  paddingBottom: "10px",
  justifyContent: "center",
  color: theme.palette.primary.main,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%);`,
  marginLeft: "10px",
  marginRight: "10px",
}));

export default function SecondaryButton({
  buttonText,
  loading,
  icon,
  onClick,
}) {
  return (
    <ButtonWrapperStyle onClick={onClick}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>{buttonText}</Typography>
          {icon != "" && (
            <Iconify
              icon={icon}
              sx={{ width: "20px", height: "20px", mx: "5px" }}
            />
          )}
        </Stack>
      )}
    </ButtonWrapperStyle>
  );
}
