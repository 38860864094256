import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Divider,
} from "@mui/material";
import palette from "src/theme/palette";
// mocks_
import account from "../../_mocks_/account";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import sidebarConfig from "./SidebarConfig";
import { useDispatch } from "react-redux";
import { setIsAuthenticated } from "src/redux/slice/userSlice";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const bgColorGradient = `linear-gradient(180deg, #5941A9 0%, #7F055F 130%);`;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
    backgroundColor: bgColorGradient,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: theme.spacing(16),
  alignItems: "center",
  // width: theme.spacing(16),
  // height: theme.spacing(16),
  justifyContent: "center",
  overflow: "hidden",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, #5941A9 0%, #7F055F 130%);`,
}));

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-between",
          // alignItems: "center",
        },
      }}
    >
      <Stack
        sx={{ mb: 1, alignItems: "center", justifyContent: "center", mt: 5 }}
      >
        <Logo />
      </Stack>
      {/* <Stack sx={{ mb: 5, alignItems: "center", justifyContent: "center" }}>
        <IconWrapperStyle>
          <img
            alt={"profile-pic"}
            src={"/static/unsplash.jpg"}
            width={"100px"}
            height={"100px"}
          />
        </IconWrapperStyle>
        <Typography
          sx={{
            color: "white",
            fontSize: "14px",
            my: 1,
          }}
        >
          Alajeseku & Sons Limited
        </Typography>
      </Stack> */}

      <NavSection navConfig={sidebarConfig} />

      {/* <Box sx={{ flexGrow: 1 }} /> */}
{/* 
      <div style={{ position: "absolute", bottom: 30, left: 40 }}>
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
          <img
            alt={"promo-pic"}
            src={"/static/icons/sidebarImg.svg"}
            width={"200px"}
            height={"200px"}
          />
        </Stack>

        <Box sx={{ px: 2.5, pb: 3 }}>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ pt: 5, borderRadius: 2, position: "relative" }}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "16px",
                mx: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setIsAuthenticated(false));
                navigate("/login", { replace: true });
              }}
            >
              Sign Out
            </Typography>

            <div sx={{ cursor: "pointer" }}>
              <img
                alt={"log-out"}
                src={`/static/icons/logout.svg`}
                width={20}
                height={20}
              />
            </div>
          </Stack>
        </Box>
      </div> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, background: bgColorGradient },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              background: bgColorGradient,
              borderRightStyle: "none",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
