import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
// material
import {
  Card,
  Container,
  Typography,
  Grid,
  Box,
  IconButton,
  Button,
  Divider,
  Stack,
  CircularProgress,
} from "@mui/material";
// components
import Page from "../components/Page";
import DashBoardCard from "src/components/DashboardCard";
import styled from "@emotion/styled";
import AllAgents from "src/sections/@dashboard/app/AllAgentsTable";
import Iconify from "src/components/Iconify";
import OutlinedButton from "src/components/OutlinedButton";
import RecentTransactions from "src/sections/@dashboard/app/RecentTransactions";
import AlertDialogSlide from "src/components/SlideInDialog";
import IncreaseLimitDialog from "src/components/IncreaseDialog";
import TransferClientDialog from "src/components/TransferDialog";
import PrimaryButton from "src/components/PrimaryButton";
import ClientTransactionTable from "src/sections/@dashboard/app/ClientTransactionTable";
import { apiFunctions } from "src/api";
import moment from "moment";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  height: "630px",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: theme.spacing(20),
  alignItems: "center",
  width: theme.spacing(16),
  height: theme.spacing(16),
  justifyContent: "center",
  overflow: "hidden",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%)`,
}));

// ----------------------------------------------------------------------

export default function ClientProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [showIncreaseDialog, setShowIncreaseDialog] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("state => ", state);

  const getTransactions = async () => {
    try {
      setLoading(true);
      const res = await apiFunctions.getSaverTransactions(state?._id);
      setLoading(false);
      if (res.status) {
        setTransactions(res.data);
        console.log("res saver trans => ", res);
      } else {
        console.log(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <Page title="Agents">
      <Container maxWidth="xl">
        <IconButton
          sx={{ position: "absolute", top: 100 }}
          onClick={() => navigate("/dashboard/savers", { replace: true })}
        >
          <Iconify icon="eva:arrow-back-outline" width={30} height={30} />
        </IconButton>
        <Divider
          sx={{ height: "40px", color: "transparent", border: "none" }}
        />
        <Grid container spacing={{ xs: 1, md: 3, sm: 2, lg: 3 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <RootStyle>
              <img
                alt={"profile-pic"}
                src="/static/icons/clientProfilePagePic.svg"
                height={300}
                width={300}
              />
              <Divider sx={{ height: "20px" }} />
              <Box
                sx={{
                  mx: "50px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: "32px" }}>
                  {state.fullName}
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  {state?.location}
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>{state.phone}</Typography>
                <Typography sx={{ fontSize: "16px" }}>{state.trade}</Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  Agent:{" "}
                  {state?.fieldAgent?.firstName +
                    " " +
                    state?.fieldAgent?.lastName}
                </Typography>
                <Divider sx={{ height: "20px" }} />
                <PrimaryButton
                  buttonText={"Transfer Client"}
                  onClick={() => setShowTransferDialog(!showTransferDialog)}
                />
              </Box>
            </RootStyle>
          </Grid>
          <Grid
            spacing={{ xs: 1, md: 3, sm: 2, lg: 3 }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <Grid
              spacing={{ xs: 1, md: 3, sm: 2, lg: 3 }}
              item
              sm={12}
              md={12}
              lg={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                spacing={{ xs: 1, md: 3, sm: 2, lg: 3 }}
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
              >
                <DashBoardCard
                  amount={state?.balance}
                  title="Account Balance"
                  icon="balance"
                />
              </Grid>

              <Grid
                spacing={{ xs: 1, md: 3, sm: 2, lg: 3 }}
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
              >
                <DashBoardCard
                  other={moment(state.nextCollectionDate).format("ll")}
                  title="Next Collection"
                  icon="calendar"
                />
              </Grid>
            </Grid>

            <Grid sx={{ mt: 3 }}>
              <Grid item>
                {loading ? (
                  <Stack
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 20,
                    }}
                  >
                    <CircularProgress />
                  </Stack>
                ) : (
                  <ClientTransactionTable transactions={transactions} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {showIncreaseDialog && (
          <AlertDialogSlide
            dialogComponent={
              <IncreaseLimitDialog
                data={state}
                isClose={() => setShowIncreaseDialog(false)}
              />
            }
            isOpen={showIncreaseDialog}
            isClose={() => setShowIncreaseDialog(false)}
          />
        )}
        {showTransferDialog && (
          <AlertDialogSlide
            dialogComponent={
              <TransferClientDialog
                client_name={state.fullName}
                client_id={state._id}
                isClose={() => setShowTransferDialog(false)}
              />
            }
            isOpen={showTransferDialog}
            isClose={() => setShowTransferDialog(false)}
          />
        )}
      </Container>
    </Page>
  );
}
