
function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    // <div style={{ flexShrink: 0, marginLeft: theme.spacing(2.5) }}>
    //   <IconButton
    //     onClick={handleFirstPageButtonClick}
    //     disabled={page === 0}
    //     aria-label="first page"
    //   >
    //     {theme.direction === 'rtl' ? "LastPageIcon"  : "FirstPageIcon" }
    //   </IconButton>
    //   <IconButton
    //     onClick={handleLastPageButtonClick}
    //     disabled={page >= Math.ceil(count / rowsPerPage) - 1}
    //     aria-label="last page"
    //   >
    //     {theme.direction === 'rtl' ? 'FirstPageIcon' : 'LastPageIcon'}
    //   </IconButton>
    // </div>
    null
  );
}

export default TablePaginationActions;
