import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Divider,
} from "@mui/material";
import palette from "src/theme/palette";
// mocks_
import account from "../../_mocks_/account";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import sidebarConfig from "./SidebarConfig";
import { useDispatch } from "react-redux";
import { setIsAuthenticated } from "src/redux/slice/userSlice";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const bgColorGradient = `linear-gradient(180deg, #5941A9 0%, #7F055F 130%);`;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
    backgroundColor: bgColorGradient,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: theme.spacing(16),
  alignItems: "center",
  // width: theme.spacing(16),
  // height: theme.spacing(16),
  justifyContent: "center",
  overflow: "hidden",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, #5941A9 0%, #7F055F 130%);`,
}));

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-between",
          // alignItems: "center",
        },
      }}
    >
      <Stack
        sx={{ mb: 1, alignItems: "center", justifyContent: "center", mt: 5 }}
      >
        <Logo />
      </Stack>
      

      <NavSection navConfig={sidebarConfig} />

    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, background: bgColorGradient },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              background: bgColorGradient,
              borderRightStyle: "none",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
