// material
import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
// components
import Page from "../components/Page";
import DashBoardCard from "../components/DashboardCard";
import { CollectionStats, TopAgents } from "../sections/@dashboard/app";
import RecentTransactions from "src/sections/@dashboard/app/RecentTransactions";
import SuspendDialog from "src/components/SuspendDialog";
import { apiFunctions } from "src/api";
import { useEffect, useState } from "react";

// ----------------------------------------------------------------------

export default function Overview() {
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [topAgents, setTopAgents] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const getDashboardSummary = async () => {
    try {
      setLoading(true);
      const res = await apiFunctions.getDahboardSummary();
      setLoading(false);
      if (res.status) {
        setSummary(res.data);
        console.log("res summary => ", res);
      } else {
        console.log(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getTopAgents = async () => {
    try {
      setLoading(true);
      const res = await apiFunctions.getTopAgents();
      setLoading(false);
      if (res.status) {
        setTopAgents(res.data);
        console.log("res top agents => ", res);
      } else {
        console.log(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getTransactions = async () => {
    try {
      setLoading(true);
      const res = await apiFunctions.getTransactions();
      setLoading(false);
      if (res.status) {
        setTransactions(res.data);
        console.log("res trans => ", res);
      } else {
        console.log(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getDashboardSummary();
    getTopAgents();
    getTransactions();
  }, []);

  return (
    <Page title="Overview">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {loading ? (
            <Grid item xs={12} md={12} lg={12}>
              <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
                <CircularProgress />
              </Stack>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <DashBoardCard
                  icon={"balance"}
                  amount={summary?.total}
                  title="Balance"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DashBoardCard
                  icon={"agent-icon-colored"}
                  other={summary?.totalAgents}
                  title="Agents"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DashBoardCard
                  icon={"client-icon-colored"}
                  other={summary?.savers}
                  title="Savers"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DashBoardCard
                  icon={"balance"}
                  other={summary?.commission}
                  title="Commission"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6} lg={8}>
            <CollectionStats />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TopAgents topAgents={topAgents} />
          </Grid>
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <Grid item>
            <RecentTransactions transactions={transactions} showAll={true} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
