import React from "react";

import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Box, Stack } from "@mui/material";
// utils
import { fShortenNumber } from "../../src/utils/formatNumber";
// component
import Iconify from "../components/Iconify";
import LoadingIndicator from "./LoadingIndicator";
import palette from "src/theme/palette";

const ButtonWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  borderRadius: "8px",
  border: "1px solid #1C2ECE",
  borderStyle: "dashed",
  alignItems: "center",
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "10px",
  paddingBottom: "10px",
  justifyContent: "center",
  color: theme.palette.primary.contrastText,
  backgroundImage: "transparent",
}));

export default function OutlinedButton({ buttonText, loading, icon, onClick }) {
  return (
    <ButtonWrapperStyle onClick={onClick}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography sx={{ color: palette.primary.main }}>
            {buttonText}
          </Typography>
          {icon != "" && (
            <Iconify
              icon={icon}
              sx={{ width: "20px", height: "20px", mx: "5px" }}
            />
          )}
        </Stack>
      )}
    </ButtonWrapperStyle>
  );
}
