// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Box } from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
// component
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(5, 5),
  color: theme.palette.primary.darker,
  backgroundColor: "white",
  width: theme.spacing(38),
  height: theme.spacing(14),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  marginRight: 20,
  borderRadius: theme.spacing(20),
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%)`,
}));

// ----------------------------------------------------------------------

export default function AppWeeklySales() {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <img alt={"cash"} src="/static/icons/cash.svg" />
      </IconWrapperStyle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          alignItems: "start",
        }}
      >
        <Typography
          sx={{ color: "black", fontSize: "30px", fontWeight: "bold" }}
        >
          N400,000
        </Typography>
        <Typography sx={{ color: "black" }}>Balance</Typography>
      </Box>
    </RootStyle>
  );
}
