import { useState } from "react";
import { Loader } from "src/components/loader";

const AffiliateForm = ({ onCancel, handleSubmit, formData, handleChange, message, isError, fullname, handlePhoneInputChange, loading}) => {
    const [isVerified, setIsVerified] = useState(false)

    return (
        <>
       
        <form className="affiliate-form" onSubmit={handleSubmit}>

            {message && (
                <p className={`${isError? 'error': 'success'}`}>
                    {message}
                </p>
            )}

            <label>Phone number</label>
            <input
                type="text"
                placeholder="e.g. +234 8123456789"
                onBlur={handlePhoneInputChange}
                name="phone"
                value={formData?.phone}
                onChange={handleChange}
            />
            {
                loading &&<Loader/>
            }

            <label>FULL NAME POPULATED</label>

            <input type="text" disabled value={fullname} />

            <label>NIN</label>
            <input type="number" placeholder="e.g. 6781234567" name="nin" value={formData?.nin} onChange={handleChange}  />

            <label>House Address</label>
            <input type="text" placeholder="Enter Address" name="address" value={formData?.address} onChange={handleChange}  />

            <label>Guarantor’s name</label>
            <input type="text" placeholder="e.g. name" name="gName" value={formData?.gName} onChange={handleChange}  />

            <label>Guarantor’s Email</label>
            <input type="email" placeholder="e.g. name" name="gEmail" value={formData?.gEmail} onChange={handleChange}  />

            <label>Guarantor’s Phone</label>
            <input
                type="tel"
                name="gPhone"
                value={formData?.gPhone}
                onChange={handleChange}
                placeholder="+234 8123456789"
                pattern="^\+?\d{1,4}?\s?\d{6,14}$"
                
            />
            <label>Guarantor’s NIN</label>
            <input type="number" placeholder="e.g. XH19L0" name="gNin" value={formData?.gNin} onChange={handleChange}  />

            <label>Guarantor’s Address</label>
            <input type="text" placeholder="e.g. XH19L0" name="gAddress" value={formData?.gAddress} onChange={handleChange}  />

            <div className="checkbox-group">
                <input
                    type="checkbox"
                    id="verified"
                    checked={isVerified}
                    onChange={() => setIsVerified(!isVerified)}
                />
                <label htmlFor="verified">These details have been verified</label>
            </div>

            <div className="form-footer">
                <button type="button" className="btn cancel-btn" onClick={onCancel}>
                    Cancel
                </button>
                <button className="btn upgrade-btn" disabled={!isVerified ? true : false} >
                    Upgrade
                </button>
            </div>


        </form>
        </>
    );
};

export default AffiliateForm;
